import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomDataGridTableB,
  CustomPagination,
  CustomSorting,
} from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import CustomFiltersN from "../../components/tables/CustomFiltersN";
import CustomSortingB from "../../components/tables/CustomSortingB";
import customConsole from "../../config/customConsole";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions";
import notificationsAction from "../../redux/actions/notification";
import CustomFiltersB from "../../components/tables/CustomFiltersB";

function ViewAllNotifications() {
  // console.log("ViewAllNotifications", ViewAllNotifications)
  const dispatch = useDispatch();

  // const moduleTypeMetaApp = useSelector(
  //   (state) => state.settingsReducer?.metaData
  // );

  const [tableRowsData, setTableRowsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notificationFilterData, setNotificationFilterData] = useState([]);
  const [recentNotificationDetails, setRecentNotificationDetails] = useState([]);
  const [isRead, setIsRead] = useState(false);

  // const [moduleType, setModuleType] = useState({});

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("");
  const [searchFieldValue, setSearchFieldValue] = useState("");
  // Sorting
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("created_at");
  const [selectedSortOrder, setSelectedSortOrder] = useState(false);
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // useEffect(() => {
  //   // Check if moduleTypeMetaApp exists and has notification_module_types
  //   if (!moduleTypeMetaApp || typeof moduleTypeMetaApp.notification_module_types !== 'object') {
  //     return;
  //   }

  //   // Transform the notification_module_types object into an array of { value, label }
  //   const filteredType = Object.entries(moduleTypeMetaApp.notification_module_types)
  //     .map(([key, value]) => ({
  //       value, // This is the type (e.g., 100, 200)
  //       label: key, // This is the key (e.g., USERS, SETTINGS)
  //     }))
  //     .sort((a, b) => a.value - b.value); // Sort by value

  //   setModuleType(filteredType); // Update state with the new array
  // }, [moduleTypeMetaApp]);

  // console.log("moduleType", moduleType)

  const fetchNotifications = async () => {
    const resp = await dispatch(
      HandleApiActions({
        ...notificationsAction?.get_all_notifications,
        params: {
          page_number: page,
          page_size: rowsPerPage,
          status: isRead,
          ...(selectedSearchField && { module_type: selectedSearchField }),
          is_old_to_new: selectedSortOrder,
          // ...(searchFieldValue && { [selectedSearchField]: searchFieldValue }),
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setRecentNotificationDetails([]);
    } else {
      setRecentNotificationDetails(resp.data);
    }
  };

  useEffect(() => {
    fetchNotifications();
    // setTableRowsData([]);
  }, [
    page,
    rowsPerPage,
    selectedSortColumn,
    isRead,
    selectedSortOrder,
    selectedSearchField,
    // searchFieldValue,
  ]);

  const handleMarkAsRead = async (notificationId) => {
    if (!notificationId) {
      console.error("No notification ID provided");
      return;
    }

    // Check if the notification is already marked as read
    const notification = recentNotificationDetails.find(
      (n) => n.notification_id === notificationId
    );
    if (notification && notification.status) {
      console.log("Notification is already marked as read:", notificationId);
      return; // Exit if already read
    }

    console.log("Mark as read clicked for ID:", notificationId);

    try {
      const apiDetails = notificationsAction.mark_read_notifications;
      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            notification_ids: [notificationId],
          },
          show_toast: true,
        })
      );

      if (resp.error) {
        console.error("Error marking notification as read:", resp.msg);
      } else {
        console.log("Notification marked as read successfully:", resp);
        // Update local state to reflect the change
        setRecentNotificationDetails((prev) =>
          prev.map((notification) =>
            notification.notification_id === notificationId
              ? { ...notification, status: true }
              : notification
          )
        );
      }
    } catch (error) {
      console.error("Error occurred while making API call:", error);
    }
  };

  const handleToggleReadUnread = () => {
    setIsRead((prev) => !prev); // Toggle read/unread state
    setPage(1); // Reset to the first page
  };

  const handleMarkAllAsRead = async () => {
    // Check if there are any unread notifications
    const unreadNotifications = recentNotificationDetails.filter(
      (notification) => !notification.status
    );

    if (unreadNotifications.length === 0) {
      console.log("All notifications are already marked as read.");
      return; // Exit if there are no unread notifications
    }

    try {
      const apiDetails = notificationsAction.mark_read_notifications;

      // Create an array of notification IDs to mark as read
      const notificationIds = unreadNotifications.map(
        (notification) => notification.notification_id
      );

      // Dispatch API action to mark all notifications as read
      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            notification_ids: notificationIds,
          },
          show_toast: true,
        })
      );

      if (resp.error) {
        console.error("Error marking all notifications as read:", resp.msg);
      } else {
        console.log("All notifications marked as read successfully:", resp);
        // Update local state to reflect the change
        setRecentNotificationDetails(
          (prev) =>
            prev.map((notification) => ({ ...notification, status: true })) // Mark all as read
        );
      }
    } catch (error) {
      console.error("Error occurred while making API call:", error);
    }
  };

  // Map notification details in table row data
  useEffect(() => {
    // Set default sort column and order if not already set
    // if (!selectedSortColumn || !selectedSortOrder) {
    //   setSelectedSortColumn("is_old_to_new");
    //   setSelectedSortOrder("false");
    // }
    const filteredNotificationList = recentNotificationDetails?.map(
      (item, index) => ({
        ...item,
        id: (page - 1) * rowsPerPage + index + 1,
        sno: (page - 1) * rowsPerPage + index + 1,
        isRead: item?.status,
        module: item?.module_details,
        module_type: item?.module_type,
        module_details: item?.module_details,
        header: item?.header,
        message: item?.message,
        created_at: item?.created_at,
        updated_at: item?.updated_at,
        status: item?.status,
        allData: item,
      })
    );
    // ?.sort((a, b) => {
    //   const aValue = a[selectedSortColumn];
    //   const bValue = b[selectedSortColumn];

    //   // Add sorting by updated_at if selectedSortColumn is 'updated_at'
    //   if (selectedSortColumn === "updated_at") {
    //     const aUpdated = new Date(a.updated_at).getTime();
    //     const bUpdated = new Date(b.updated_at).getTime();
    //     return selectedSortOrder === "ASC"
    //       ? aUpdated - bUpdated
    //       : bUpdated - aUpdated;
    //   }

    //   if (aValue < bValue) return selectedSortOrder === "ASC" ? -1 : 1;
    //   if (aValue > bValue) return selectedSortOrder === "ASC" ? 1 : -1;

    //   return 0;
    // });

    // Handle pagination hasMore
    if (filteredNotificationList?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    setTableRowsData(filteredNotificationList);
  }, [recentNotificationDetails]);

  // useEffect(() => {
  //   const filteredStatus = notificationFilterData
  //     .map((status) => ({
  //       value: status.value,
  //       label: status.key,
  //     }))
  //     .sort((a, b) => a.value - b.value);
  //     setNotificationFilterData(filteredStatus);
  // }, [notificationFilterData]);

  //Function to handle module type
  const getModuleType = (statusCode) => {
    console.log(moduleType);
    return moduleType[statusCode] || "-";
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  // const handleMarkAsRead = (id) => {
  //   setTableRowsData((prev) =>
  //     prev.map((item) => (item.id === id ? { ...item, isRead: true } : item))
  //   );
  // };

  const handleOpenDialog = (notification) => {
    setSelectedNotification(notification);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedNotification(null);
  };

  const handleUnsubscribe = () => {
    // Implement your unsubscribe logic here
    console.log(
      `Unsubscribed from notification: ${selectedNotification.header}`
    );
    handleCloseDialog(); // Close the dialog after handling
  };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle filter change
  const handleFilterChange = (searchField) => {
    setSelectedSearchField(searchField);
    // setSearchFieldValue(searchValue);
    // setPage(1);
    handleFilterClose();
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("", "");
  };

  const handleFilterApplyNow = (searchField) => {
    handleFilterChange(searchField);
  };

  // Open sort menu
  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  // Handle sort close
  const handleSortClose = () => {
    setAnchorElSort(null);
    setOpenSort(false);
  };

  // Handle sort change
  const handleSortChange = (column, order) => {
    setSelectedSortColumn(column);
    setSelectedSortOrder(order);
    // setPage(1);
    handleSortClose();
  };

  // Reset the sort values
  const handleSortResetAll = () => {
    handleSortChange("false");
  };

  // useEffect(() => {
  //   handleFilterResetAll();
  //   handleSortResetAll();
  // }, [recentNotificationDetails]);

  // Apply sorting
  const handleSortApplyNow = (selectedSortColumn, selectedSortOrder) => {
    handleSortChange(selectedSortColumn, selectedSortOrder);
  };

  //Applying rows color
  const getRowClassName = (params) => {
    return params.row.isRead ? "rowRead" : "rowUnread";
  };

  const COLUMNS = [
    {
      field: "sno",
      headerName: "S.NO",
      headerAlign: "center",
      width: 80,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center" }}>{params.value || "-"}</Box>
      ),
    },
    {
      field: "module_type",
      headerName: "MODULE",
      headerAlign: "center",
      width: 140,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center" }}>{getModuleType(params.value)}</Box>
      ),
    },
    {
      field: "header",
      headerName: "HEADER",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Box sx={{ textAlign: "left" }}>{params.value || "-"}</Box>
      ),
    },
    {
      field: "message",
      headerName: "MESSAGE",
      headerAlign: "center",
      width: 300,
      renderCell: (params) => (
        <Box sx={{ textAlign: "left" }}>{params?.value || "-"}</Box>
      ),
    },
    {
      field: "module_details",
      headerName: "CHANGES",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => {
        const details = params?.value || {};
        const detailEntries = Object?.entries(details);
    
        return (
          <Box sx={{ textAlign: "left" }}>
            {detailEntries.length > 0 ? (
              detailEntries?.map(([key, value]) => {
                if (typeof value == 'object' && value !== null) {
                  // If the value is an object, render its properties
                  return (
                    <Box key={key}>
                      <strong>{key?.replace(/_/g, ' ')}:</strong>
                      <Box sx={{ pl: 2 }}>
                        {Array?.isArray(value) ? (
                          value?.map((item, index) => (
                            <Box key={item?.id || index}>
                              {Object?.entries(item)?.map(([subKey, subValue]) => (
                                <Box key={subKey}>
                                  <strong>{subKey?.replace(/_/g, ' ')}:</strong> {subValue ? String(subValue) : "-"}
                                </Box>
                              ))}
                            </Box>
                          ))
                        ) : (
                          Object?.entries(value)?.map(([subKey, subValue]) => (
                            <Box key={subKey}>
                              <strong>{subKey?.replace(/_/g, ' ')}:</strong> {subValue ? String(subValue) : "-"}
                            </Box>
                          ))
                        )}
                      </Box>
                    </Box>
                  );
                }
    
                // Render the value as a string or a fallback
                return (
                  <Box key={key}>
                    <strong>{key?.replace(/_/g, ' ')}:</strong> {String(value) || "-"}
                  </Box>
                );
              })
            ) : (
              <Box>No details available</Box>
            )}
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "CREATED AT",
      headerAlign: "center",
      width: 180,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center" }}>
          {params.value ? new Date(params.value).toLocaleString() : "-"}
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center" }}>
          <Box>
            <Button
              onClick={() => handleMarkAsRead(params.row.notification_id)} // Pass correct notification ID
              size="small"
              sx={{ color: params.row.status ? "black" : "primary.main" }} // Check the status
            >
              {params.row.status ? "Read" : "Mark as read"}
            </Button>
          </Box>
        </Box>
      ),
    },

    // {
    //   field: "status",
    //   headerName: "STATUS",
    //   headerAlign: "center",
    //   width: 200,
    //   renderCell: (params) => (
    //     <Box sx={{ textAlign: "center" }}>
    //       <Box>
    //         <Button
    //           onClick={() => handleMarkAsRead(params.row.id)}
    //           size="small"
    //           sx={{ color: params.row.status ? "black" : "primary.main" }} // Use status to determine color
    //         >
    //           {params.row.status ? "Read" : "Mark as read"}
    //         </Button>
    //       </Box>
    //       {/* Uncomment if you need subscription functionality
    //       <Box>
    //         <Button
    //           onClick={() => handleOpenDialog(params.row)}
    //           size="small"
    //           sx={{ marginLeft: 1 }}
    //         >
    //           {params.row.subscribe ? "Un-Subscribe" : "Subscribe"}
    //         </Button>
    //       </Box> */}
    //     </Box>
    //   ),
    // }
  ];

  return (
    <Box>
      {/* Add/Filter/Sorting buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={styles.btnmarkallread}>
          <Button onClick={handleMarkAllAsRead}>Mark All as Read</Button>
        </Box>
        <Box sx={styles.btnsContainer}>
        <CustomButton
            variant="contained"
            btnName={isRead ? "Show Unread" : "Show Read"}
            handleOnClick={handleToggleReadUnread}
            sx={{ backgroundColor: "green", color: "white", borderRadius: 5, fontSize: 12 }}
          />

          <CustomFiltersB
            anchorEl={anchorElFilter}
            open={openFilter}
            handleClose={handleFilterClose}
            mainOptionsList={[
              {
                value: 100,
                label: "USERS",
                type: "select",
              },
              {
                value: 200,
                label: "SETTINGS",
                type: "select",
              },
              {
                value: 300,
                label: "LEGAL",
                type: "select",
              },
              {
                value: 400,
                label: "PROJECT",
                type: "select",
              },
              {
                value: 500,
                label: "ZONE",
                type: "select",
              },
              {
                value: 600,
                label: "ECU",
                type: "select",
              },
              {
                value: 700,
                label: "SWM",
                type: "select",
              },
              {
                value: 800,
                label: "TICKETS",
                type: "select",
              },
            ]}
            selectedMainOption={selectedSearchField}
            // selectedMainOptionValue={searchFieldValue}
            handleFilterClick={handleFilterClick}
            onReset={handleFilterResetAll}
            onApplyNow={handleFilterApplyNow}
          />

          <CustomSortingB
            anchorEl={anchorElSort}
            open={openSort}
            handleClose={handleSortClose}
            sortOptionsList={[
              { value: "created_at", label: "Created At" },
            ]}
            selectedSortColumn={selectedSortColumn}
            selectedSortOrder={selectedSortOrder}
            handleSortClick={handleSortClick}
            onReset={handleSortResetAll}
            onApplyNow={(column, order) => handleSortApplyNow(column, order)}
          />
        </Box>
      </Box>

      <Box sx={styles.box}>
        {tableRowsData?.length > 0 ? (
          <Paper sx={styles.paper}>
            <CustomDataGridTableB
              tableRowsData={tableRowsData}
              columns={COLUMNS}
              rowCount={tableRowsData.length}
              isMultiLine={true}
              handleTableRowClick={() => null}
              getRowClassName={getRowClassName}
            />
            <Paper sx={styles.paginationPaper}>
              <CustomPagination
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                hasMore={hasMore}
              />
            </Paper>
          </Paper>
        ) : (
          <Typography sx={{ padding: 2, textAlign: "center" }}>
            Notifications not available
          </Typography>
        )}

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          BackdropProps={{
            style: { backgroundColor: "transparent" },
          }}
          PaperProps={{
            style: {
              width: "400px",
              height: "180px",
              borderRadius: "15px",
            },
          }}
        >
          <DialogTitle>
            <IconButton onClick={handleCloseDialog} sx={styles.closeButton}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Are you sure you want to Un-subscribe from notifications?
            </Typography>
            <Box sx={{ textAlign: "center", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={handleUnsubscribe}
                sx={{ backgroundColor: "green", color: "white" }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={handleCloseDialog}
                color="error"
                sx={{ marginLeft: 1 }}
              >
                No
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

export default ViewAllNotifications;

const moduleType = {
  100: (
    <Chip
      sx={{
        // fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#000000",
        background: "none",
        // color: "#000000",
        // fontSize: "12px",
      }}
      label={"USERS"}
      size="medium"
    />
  ),
  200: (
    <Chip
      sx={{
        // fontWeight: "bold",
        // // boxShadow: "3",
        // // borderRadius: "5px",
        // // backgroundColor: "#0076a8",
        background: "none",
        // color: "#0076a8",
        // fontSize: "12px",
      }}
      label={"SETTINGS"}
      size="medium"
    />
  ),
  300: (
    <Chip
      sx={{
        // fontWeight: "bold",
        // // boxShadow: "3",
        // // borderRadius: "5px",
        // // backgroundColor: "#0076a8",
        background: "none",
        // color: "#0076a8",
        // fontSize: "12px",
      }}
      label={"LEGAL"}
      size="medium"
    />
  ),
  400: (
    <Chip
      sx={{
        // fontWeight: "bold",
        // // boxShadow: "3",
        // // borderRadius: "5px",
        // // backgroundColor: "#0076a8",
        background: "none",
        // color: "#0076a8",
        // fontSize: "12px",
      }}
      label={"PROJECT"}
      size="medium"
    />
  ),
  500: (
    <Chip
      sx={{
        // fontWeight: "bold",
        // // boxShadow: "3",
        // // borderRadius: "5px",
        background: "none",
        // // backgroundColor: "#0076a8",
        // color: "#0076a8",
        // fontSize: "12px",
      }}
      label={"ZONE"}
      size="medium"
    />
  ),
  600: (
    <Chip
      sx={{
        // fontWeight: "bold",
        // // boxShadow: "3",
        // // borderRadius: "5px",
        // // backgroundColor: "#26890D",
        background: "none",
        // color: "#26890D",
        // fontSize: "12px",
      }}
      label={"ECU"}
      size="medium"
    />
  ),
  700: (
    <Chip
      sx={{
        // fontWeight: "bold",
        // // boxShadow: "3",
        // // borderRadius: "5px",
        // // backgroundColor: "#0076a8",
        background: "none",
        // color: "#0076a8",
        // fontSize: "12px",
      }}
      label={"SWM"}
      size="medium"
    />
  ),
  800: (
    <Chip
      sx={{
        // fontWeight: "bold",
        // // boxShadow: "3",
        // // borderRadius: "5px",
        // // backgroundColor: "#0076a8",
        background: "none",
        // color: "#0076a8",
        // fontSize: "12px",
      }}
      label={"TICKETS"}
      size="medium"
    />
  ),
};

const styles = {
  box: {
    height: "100vh",
    width: "100%",
  },
  paper: {
    boxShadow: 3,
    height: "70%",
    width: "auto",
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
  },
  closeButton: {
    position: "absolute",
    right: 2,
    top: 2,
  },
  btnsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 1,
    mt: 2,
    gap: 1,
  },
  btnmarkallread: {
    display: "flex",
    justifyContent: "flex-start",
  },
  // rowRead: {
  //   backgroundColor: "#f0f0f0",
  // },
  // rowUnread: {
  //   backgroundColor: "white",
  // },
};
