/**
 * @author Abdul Rahim M-abdul@au79consulting.com
 * @date 13-07-2024
 * @description  AdminSettings API documentation Apis
 */
import actionType from "../actionTypes";
const adminSettingsAPI = {
  //AdminSettings Action
  get_admin_settings: {
    api_name: "/UCM/settings/get",
    https_method: "GET",
    action_type: actionType.GET_ADMIN_SETTINGS,
    description: "Get the Admin Settings",
  },

  //Zone Details
  add_zone_details: {
    api_name: "/UCM/settings/zone/details",
    https_method: "PUT",
    action_type: actionType.ADD_ZONES,
    description: " Add Zone Settings",
  },
  delete_zone_details: {
    api_name: "/UCM/settings/zone/details/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_ZONES,
    description: "DELETE  Zone Settings",
  },

  //Architecture Types
  add_architecture_types: {
    api_name: "/UCM/settings/architecture/types",
    https_method: "PUT",
    action_type: actionType.ADD_ARCHITECTURE_TYPES,
    description: "ADD Architecture Types",
  },
  delete_architecture_types: {
    api_name: "/UCM/settings/architecture/types/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_ARCHITECTURE_TYPES,
    description: "DELETE Architecture Types",
  },

  //Powertrainclass

  add_powertrain_class: {
    api_name: "/UCM/settings/pc",
    https_method: "POST",
    action_type: actionType.ADD_POWETRAIN_CLASS,
    description: "ADD PowerTrain Class",
  },
  get_powertrain_class: {
    api_name: "/UCM/settings/pc",
    https_method: "GET",
    action_type: actionType.GET_POWETRAIN_CLASS,
    description: "GET PowerTrain Class",
  },
  update_powertrain_class: {
    api_name: "/UCM/settings/pc",
    https_method: "PUT",
    action_type: actionType.UPDATE_POWETRAIN_CLASS,
    description: "UPDATE PowerTrain Class",
  },
  delete_powertrain_class: {
    api_name: "/UCM/settings/pc/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_POWETRAIN_CLASS,
    description: "DELETE PowerTrain Class",
  },

  //Vehicle Function
  add_vehicle_function: {
    api_name: "/UCM/settings/vf",
    https_method: "POST",
    action_type: actionType.ADD_VEHICLE_FUNCTION,
    description: "ADD Vehicle Function",
  },
  get_vehicle_function: {
    api_name: "/UCM/settings/vf",
    https_method: "GET",
    action_type: actionType.GET_VEHICLE_FUNCTION,
    description: "GET Vehicle Function",
  },
  update_vehicle_function: {
    api_name: "/UCM/settings/vf",
    https_method: "PUT",
    action_type: actionType.UPDATE_VEHICLE_FUNCTION,
    description: "UPDATE Vehicle Function",
  },
  delete_vehicle_function: {
    api_name: "/UCM/settings/vf/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_VEHICLE_FUNCTION,
    description: "DELETE Vehicle Function",
  },

  //Vehicle Class

  add_vehicle_class: {
    api_name: "/UCM/settings/vc",
    https_method: "POST",
    action_type: actionType.ADD_VEHICLE_CLASS,
    description: "ADD Vehicle Class",
  },
  get_vehicle_class: {
    api_name: "/UCM/settings/vc",
    https_method: "GET",
    action_type: actionType.GET_VEHICLE_CLASS,
    description: "GET Vehicle Class",
  },
  update_vehicle_class: {
    api_name: "/UCM/settings/vc",
    https_method: "PUT",
    action_type: actionType.UPDATE_VEHICLE_CLASS,
    description: "UPDATE Vehicle Class",
  },
  delete_vehicle_class: {
    api_name: "/UCM/settings/vc/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_VEHICLE_CLASS,
    description: "DELETE Vehicle Class",
  },

  //mft_details

  add_mft_details: {
    api_name: "/UCM/settings/add/vehicle-manufacturer",
    https_method: "PUT",
    action_type: actionType.ADD_MFT_DETAILS,
    description: "Add Mft Details",
  },
  delete_mft_details: {
    api_name: "/UCM/settings/remove/vehicle-manufacturer",
    https_method: "PUT",
    action_type: actionType.DELETE_MFT_DETAILS,
    description: "Remove Mft Details",
  },

  //delivery Market Details

  add_dellivery_market_details: {
    api_name: "/UCM/settings/add/delivery-market",
    https_method: "PUT",
    action_type: actionType.ADD_DELIVERY_MARKET_DETAILS,
    description: "Add DMarket Details",
  },
  delete_deliver_market_detials: {
    api_name: "/UCM/settings/remove/delivery-market",
    https_method: "PUT",
    action_type: actionType.DELETE_DELIVERY_MARKET_DETAILS,
    description: "Remove DMarket Details",
  },
};

export default adminSettingsAPI;
