/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 11-06-2024
 * @description This file is having the major route to redirect to a particular page
 */
//Importing the Components from the modules/libraries
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";
import Dashboard from "./main-layout/Dashboard";
import "react-toastify/dist/ReactToastify.css";
import {
  Authentication,
  Home,
  ProjectConfig,
  Vta,
  LabCar,
  PageNotFound,
  ProfileConfig,
  UserManagement,
  LegalRequirements,
  Templates,
  AdminSettings,
  ViewAllNotifications,
} from "./pages";
import ProtectedRoute from "./ProtectedRoute";
import Docs from "./pages/documetation/Docs";
import Notifications from "./main-layout/TopBar/Notifications";

//Main App functional component
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/authenticate" element={<Authentication />} />
        <Route exact path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          {" "}
          <Route
            path="/dashboard"
            element={<Navigate to="/dashboard/home" />}
          />
          <Route path="/dashboard/home" element={<Home />} />
          <Route path="/dashboard/docs" element={<Docs />} />
          <Route path="/dashboard/project-config" element={<ProjectConfig />} />
          <Route path="/dashboard/type-approval" element={<Vta />} />
          <Route path="/dashboard/labcar" element={<LabCar />} />
          <Route
            path="/dashboard/user-management"
            element={<UserManagement />}
          />
          <Route
            path="/dashboard/legal-requirements"
            element={<LegalRequirements />}
          />
          <Route path="/dashboard/templates" element={<Templates />} />
          <Route path="/dashboard/admin-settings" element={<AdminSettings />} />
          <Route path="/dashboard/profile" element={<ProfileConfig />} />
          <Route path="/dashboard/notifications" element={<Notifications />} />
          <Route
            path="/dashboard/view-all-notifications"
            element={<ViewAllNotifications />}
          />{" "}
        </Route>
        <Route path="/dashboard/*" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
