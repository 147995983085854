/**
 * @author Bharathan - bharathan@au79consulting.com
 * @date 19-09-2024
 * @description This component displays a list of tickets and allows viewing and updating ticket status.
 */

// Importing necessary components and hooks
import React, { useEffect, useState } from "react";
import {
  CustomDataGridTableB,
  CustomDataGridTableN,
  CustomPagination,
  CustomSorting,
  SearchSelectTextField,
} from "../../../components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions, userManagementApis } from "../../../redux/actions";
import { convertUtcToTimeZone } from "../../../utils/convertUtcToTimeZone";
import typeApprovalAPI from "../../../redux/actions/typeApprovalAPI";
import appTheme from "../../../assets/AppTheme/appTheme";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Slide,
  styled,
} from "@mui/material";
import moment, { max } from "moment/moment";
import CustomFiltersN from "../../../components/tables/CustomFiltersN";
import { useLocation } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function IntersDelTicket({
  selectedProjectId,
  projectOptionsList,
  selectedProject,
  setSelectedProject,
  selectedProjectDetails,
  setTabValue
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectIdFromurl = queryParams.get("project_id");
  const statusFromurl = queryParams.get("status");

  //Diapatch and Selector function to handle redux store
  const dispatch = useDispatch();
  // const ticketData = useSelector((state) => state.typeApprovalReducer);
  // const userDetails = useSelector((state) => state.userManagementReducer);
  // console.log("userDetails", userDetails)
  const ticketStatusMetaApp = useSelector(
    (state) => state.settingsReducer?.metaData
  );
  const profileDetails = useSelector((store) => store.profileReducer);

  const ticketStatusOptions = ticketStatusMetaApp.snow_ticket_status;

  // State hooks for managing component state
  const [tableRowsData, setTableRowsData] = useState([]);
  const [dialogData, setDialogData] = useState(null);
  const [legalDialogData, setLegalDialogData] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLegalDialogOpen, setIsLegalDialogOpen] = useState(false);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [intersectionId, setIntersectionId] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [techUserDetails, setTechUserDetails] = useState("");
  const [techUserTickets, setTechUserTickets] = useState("");

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("status");
  const [searchFieldValue, setSearchFieldValue] = useState(statusFromurl || "");
  const [filteredTickets, setFilteredTickets] = useState([]);
  // Sorting
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("updated_at");
  const [selectedSortOrder, setSelectedSortOrder] = useState("DESC");
  // Status
  const [ticketStatusData, setTicketStatusData] = useState([]);
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(5); // Countdown timer in seconds
  const [intervalId, setIntervalId] = useState(null);
  const [currentTicketId, setCurrentTicketId] = useState(null);
  const [userDetailsMap, setUserDetailsMap] = useState(new Map());
  const [usersDetail, setUsersDetail] = useState(new Map());

  //Effect to handle status dropdown to filter tickets

  useEffect(() => {
    // Assuming ticketStatusMetaApp.snow_ticket_status is available
    if (
      !ticketStatusMetaApp ||
      !Array.isArray(ticketStatusMetaApp.snow_ticket_status)
    )
      return;

    const filteredStatus = ticketStatusMetaApp.snow_ticket_status
      .filter((status) => status.value !== undefined)
      .map((status) => ({
        value: status.value,
        label: status.key,
      }))
      .sort((a, b) => a.value - b.value);

    setTicketStatusData(filteredStatus);
  }, [ticketStatusMetaApp]);

  // //Effect to handle filter for tickets
  // useEffect(() => {
  //   if (!selectedSearchField || !searchFieldValue) {
  //     setTableRowsData(ticketDetails);
  //   } else {
  //     handleFilterApplyNow(selectedSearchField, searchFieldValue);
  //   }
  // }, [selectedSearchField, searchFieldValue, ticketDetails]);

  // Update ticket details when ticketData changes
  // useEffect(() => {
  //   setTicketDetails(ticketData?.typeApproval || []);
  // }, [ticketData]);

  // Update profile details when ticketData changes
  // useEffect(() => {
  //   setTechUserDetails(profileDetails?.usersList || []);
  // }, [ticketData]);

  // Fetch tickets when component mounts
  // useEffect(() => {
  //   if (selectedProjectId) {
  //     dispatch(
  //       HandleApiActions({
  //         ...typeApprovalAPI?.get_tickets,
  //         params: { project_id: selectedProjectId },
  //         show_toast: false,
  //       })
  //     );
  //   }
  //   setPage(1);
  //   setSelectedIds([]);
  // }, [selectedProjectId]);

  //  useEffect(() => {
  //   if (projectOptionsList.length > 0) {
  //     setSelectedProject(projectOptionsList[0]);
  //   }
  // }, [projectOptionsList]);

  //Modified by Bharathan to select the project from the dashboard link
  useEffect(() => {
    if (projectIdFromurl) {
      const selectedProject = projectOptionsList.find(
        (project) => project.value === projectIdFromurl
      );
      if (selectedProject) {
        setSelectedProject(selectedProject);
      }
    }
    // else if (projectOptionsList.length > 0) {
    //   setSelectedProject(projectOptionsList[0]);
    // }
  }, [projectIdFromurl, projectOptionsList]);

  // Fetch tickets when component mounts or when projectId or status changes
  // useEffect(() => {
  //   if (selectedProjectId) {
  //     dispatch(
  //       HandleApiActions({
  //         ...typeApprovalAPI?.get_tickets,
  //         params: { project_id: selectedProjectId },
  //         show_toast: false,
  //       })
  //     );
  //   }
  //   setPage(1);
  //   setSelectedIds([]);
  // }, [selectedProjectId, dispatch]);

  // Fetch tickets when component mounts or when projectId or status changes
  // useEffect(() => {
  //   if (projectId && status) {
  //     const selectedProjectOption = projectOptionsList.find(p => p.value === projectId);
  //     setSelectedProject(selectedProjectOption);
  //     setSelectedStatus(status);

  //     dispatch(
  //       HandleApiActions({
  //         ...typeApprovalAPI?.get_tickets,
  //         params: {
  //           project_id: projectId,
  //           status: status,
  //         },
  //         show_toast: false,
  //       })
  //     );
  //   }
  // }, [projectId, status, projectOptionsList]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const apiDetails = userManagementApis?.get_users_list_pa;
        delete apiDetails.action_type;
        const resp = await dispatch(
          HandleApiActions({
            ...apiDetails,
            show_toast: false,
          })
        );

        if (!resp.error) {
          const users = resp?.data || [];
          const userMap = new Map(users.map((user) => [user?.user_id, user]));
          setUserDetailsMap(userMap);
          setUsersDetail(users);
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  // Map ticket details in table row data
  useEffect(() => {
    // Set default sort column and order if not already set
    // if (!selectedSortColumn || !selectedSortOrder) {
    //   setSelectedSortColumn("updated_at");
    //   setSelectedSortOrder("DESC");
    // }

    const filteredTicketList = ticketDetails
      ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
      ?.map((item, index) => ({
        ...item,
        id: (page - 1) * rowsPerPage + index + 1,
        intr_id: item?.intr_id,
        tech_service_users: item?.tech_service_users,
        oem_users: item?.oem_users,
        users: item?.tech_service_users || item?.oem_users,
        ticket_id: item?.ticket_id,
        status: item?.status,
        ticket_status: item?.ticket_id || item?.status,
        ls_details: item?.ls_details,
        lr_details: item?.lr_details,
        ls_lr_details: item?.ls_details || item?.lr_details,
        swm_details: item?.swm_details,
        ee_details: item?.ee_details,
        description: item?.description,
        created_at: convertUtcToTimeZone(
          item?.created_at,
          profileDetails?.profile?.profile?.region
        ),
        created_by: item?.created_by,
        updated_at: convertUtcToTimeZone(
          item?.updated_at,
          profileDetails?.profile?.profile?.region
        ),
        updated_by: item?.updated_by,
        allData: item,
      }))
      // ?.sort((a, b) => {
      //   const aValue = a[selectedSortColumn];
      //   const bValue = b[selectedSortColumn];

      //   // Add sorting by updated_at if selectedSortColumn is 'updated_at'
      //   if (selectedSortColumn === "updated_at") {
      //     const aUpdated = new Date(a.updated_at).getTime();
      //     const bUpdated = new Date(b.updated_at).getTime();
      //     return selectedSortOrder === "ASC"
      //       ? aUpdated - bUpdated
      //       : bUpdated - aUpdated;
      //   }

      //   if (aValue < bValue) return selectedSortOrder === "ASC" ? -1 : 1;
      //   if (aValue > bValue) return selectedSortOrder === "ASC" ? 1 : -1;

      //   return 0;
      // });

    // Handle pagination hasMore
    if (filteredTicketList?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    setTableRowsData(filteredTicketList);
  }, [ticketDetails, selectedSortColumn, selectedSortOrder]);

  // // Effect to update the rows data based on pagination
  // useEffect(() => {
  //   const startIdx = page * rowsPerPage;
  //   const endIdx = startIdx + rowsPerPage;
  //   setTableRowsData(tableRowsData.slice(startIdx, endIdx));
  // }, [page, rowsPerPage]);

  //Effect to handle tickets while filter or page changes
  useEffect(() => {
    handleGetTicketList();
    setTableRowsData([]);
  }, [
    page,
    rowsPerPage,
    selectedSortColumn,
    selectedSortOrder,
    selectedProjectId,
    // selectedIds,
  ]);

  useEffect(() => {
    if (open) {
      // Start the countdown timer when the dialog opens
      const id = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(id); // Stop the interval when time is up
            handleRedirect(); // Redirect after time is up
            return 0;
          }
          return prev - 1;
        });
      }, 1000); // Update every second
      setIntervalId(id);

      return () => {
        clearInterval(id); // Cleanup interval on component unmount or when dialog is closed
      };
    }
  }, [open]);

  // const getUserFullNameById = (userId) => {
  //   const user = userDetails?.usersList?.find(
  //     (user) => user.user_id === userId
  //   );
  //   return user?.fullname;
  // };

  //Dispatch function to handle tickets
  const handleGetTicketList = async () => {
    const resp = await dispatch(
      HandleApiActions({
        ...typeApprovalAPI?.get_tickets,
        params: {
          project_id: selectedProjectId,
          page_number: page,
          page_size: rowsPerPage,
          sort_column: selectedSortColumn,
          sort_order: selectedSortOrder,
          ...(searchFieldValue && { [selectedSearchField]: searchFieldValue }),
        },
        show_toast: false,
      })
    );
    if (resp.error) {
      setTicketDetails([]);
    } else {
      setTicketDetails(resp.data);
    }
  };

  useEffect(() => {
    handleFilterResetAll();
    handleSortResetAll();
  }, [selectedProjectId]);

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleGetTicketList();
    handleFilterChange("status", "");
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);
    setPage(1);
    handleFilterClose();
  };

  // Function to handle filter
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  // Effect to update the filter based on query parameters
  useEffect(() => {
    if (statusFromurl) {
      setSelectedSearchField("status");
      setSearchFieldValue(statusFromurl);
      handleFilterChange("status", statusFromurl);
    }
  }, [statusFromurl]);

  useEffect(() => {
    if (searchFieldValue) {
      handleGetTicketList();
      handleFilterChange(selectedSearchField, searchFieldValue);
    }
  }, [searchFieldValue, selectedSearchField]);

  // Function to handle filter
  // const handleFilterApplyNow = (searchField, searchValue) => {
  //   // Check if query parameters have any filters
  //   if (statusFromurl) {
  //     // If a status filter exists, set it as the search field and value
  //     handleFilterChange('status', statusFromurl);
  //   } else {
  //     // Otherwise, use the provided searchField and searchValue
  //     handleFilterChange(searchField, searchValue);
  //   }
  // };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Open sort menu
  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  // Handle sort close
  const handleSortClose = () => {
    setAnchorElSort(null);
    setOpenSort(false);
  };

  // Handle sort change
  const handleSortChange = (column, order) => {
    setSelectedSortColumn(column);
    setSelectedSortOrder(order);
    setPage(1);
    handleSortClose();
  };

  // Reset the sort values
  const handleSortResetAll = () => {
    handleSortChange("updated_at", "DESC");
  };

  // Apply sorting
  const handleSortApplyNow = (selectedSortColumn, selectedSortOrder) => {
    handleSortChange(selectedSortColumn, selectedSortOrder);
  };

  //Function to handle Toggle Software module Dialog open/closed
  const toggleDialog = (newOpen) => () => {
    setIsDialogOpen(newOpen);
  };

    //Function to handle Toggle Legal Dialog open/closed
    const toggleLegalDialog = (newOpen) => () => {
      setIsLegalDialogOpen(newOpen);
    };

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  //Function to handle ticket status
  const getTicketStatusName = (statusCode) => {
    return ticketStatus[statusCode] || "-";
  };

  //Function for show the project status
  const getProjectStatusName = (statusCode) => {
    return projectStatus[statusCode] || "-";
  };

  // Handle checkbox change
  const handleCheckboxChange = (intr_id) => {
    setSelectedIds((prevSelected) => {
      const updatedSelection = prevSelected?.includes(intr_id)
        ? prevSelected?.filter((selectedIds) => selectedIds !== intr_id)
        : [...prevSelected, intr_id];
      // console.log("Selected IDs:", updatedSelection);
      return updatedSelection;
    });
  };
  // console.log("selectedIds", selectedIds);

  //Function to handle re-create ticket
  const handleReCreateTicket = () => {
    const payload = {
      intersection_ids: selectedIds?.map((el) => Number(el)),
      current_time: moment().format(),
    };
    // console.log("Payload before sending:", payload);
    dispatch(
      HandleApiActions({
        ...typeApprovalAPI?.re_create_tickets,
        params: payload,
        show_toast: true,
        onSuccess: (response) => {
          // console.log("API Response:", response);
          const newTickets = response?.data?.snow_resp?.data?.map((ticket) => ({
            id: ticket?.id,
            intr_id: ticket?.intr_id,
            ticket_id: ticket?.ticket_id,
            status: ticket?.status,
          }));
          // console.log("New tickets data:", newTickets);
          if (newTickets.length > 0) {
            setTableRowsData((prevData) => {
              if (selectedProjectId === payload?.project_id) {
                const updatedData = prevData?.filter(
                  (ticket) => !selectedIds?.includes(ticket?.id)
                );
                return [...newTickets, ...updatedData];
              }
              return prevData;
            });
          }
          setSelectedIds([]);
        },
        onError: (error) => {
          // console.error("Error creating tickets:", error);
          setSelectedIds([]);
        },
      })
    );
    setSelectedIds([]);
  };

  //Function to handle delete draft ticket
  const handleDeleteDraftTicket = async () => {
    const payload = {
      intersection_ids: selectedIds?.map((el) => Number(el)),
      project_id: parseInt(selectedProjectId),
    };
    // Dispatch delete action
    const response = await dispatch(
      HandleApiActions({
        ...typeApprovalAPI?.delete_tickets,
        params: payload,
        show_toast: false,
      })
    );
    // Check for successful deletion
    if (!response.error) {
      // Fetch the updated ticket list
      handleGetTicketList();
    }
    setSelectedIds([]);
  };

  const LinkButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    padding: 0,
    minWidth: "auto",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  }));

  const CountdownTimer = styled(Typography)(({ theme }) => ({
    backgroundColor: "green",
    color: "white",
    borderRadius: "30px",
    textAlign: "center",
    width: "100px",
    cursor: "pointer",
  }));

  const handleClickOpen = (ticket_id) => {
    setCurrentTicketId(ticket_id);
    setOpen(true);
    setTimer(5); // Reset timer when dialog opens
  };

  const handleClose = () => {
    setOpen(false);
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval if the dialog is closed manually
    }
  };

  const handleRedirect = () => {
    setOpen(false);
    if (intervalId) {
      clearInterval(intervalId); // Clear the interval if redirecting
      setIntervalId(null);
    }
    if (currentTicketId) {
      // console.log("Redirecting to the URL"); // Debugging line
      window.open(
        `https://ven07074.service-now.com/incident.do?sysparm_query=number=${currentTicketId}`,
        "_blank"
      );
    }
  };

  // Column definitions for the data grid table
  const COLUMNS = [
    {
      field: "select",
      headerName: "Select",
      headerAlign: "center",
      width: 70,
      renderCell: (params) => {
        const { intr_id, status } = params.row;

        // Only show checkbox if status is 101
        if (status !== 101) {
          return null;
        }

        const isChecked = selectedIds.includes(intr_id);
        return (
          <Checkbox
            checked={isChecked}
            onChange={() => handleCheckboxChange(intr_id)}
            sx={{
              color: "primary.main",
              "&.Mui-disabled": {
                color: "grey.500",
                "& .MuiSvgIcon-root": {
                  fill: "grey.500",
                },
              },
            }}
          />
        );
      },
    },

    {
      field: "intr_id",
      headerName: "INTERSECTION ID",
      headerAlign: "center",
      width: 140,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center" }}>{params?.value || "-"}</Box>
      ),
    },
    //  {
    //    field: "ticket_id",
    //    headerName: "TICKET ID",
    //    headerAlign: "center",
    //    width: 140,
    //    renderCell: (params) => (
    //      <Box>
    //        <LinkButton onClick={() => handleClickOpen(params.value)}>
    //          {params?.value}
    //        </LinkButton>

    //        <Dialog
    //          open={open}
    //          onClose={handleClose}
    //          anchor="right"
    //          BackdropProps={{
    //            style: { backgroundColor: "transparent" },
    //          }}
    //          PaperProps={{
    //            style: {
    //              width: "400px",
    //              height: "180px",
    //              borderRadius: "15px",
    //            },
    //          }}
    //        >
    //          <DialogTitle>
    //            <IconButton
    //              // edge="end"
    //              // color="inherit"
    //              onClick={handleClose}
    //              sx={styles.closeButton}
    //            >
    //              <CloseIcon />
    //            </IconButton>
    //          </DialogTitle>

    //          <DialogContent>
    //            <Typography
    //              variant="h6"
    //              sx={{ fontWeight: "bold", textAlign: "center" }}
    //            >
    //              Redirecting to ServiceNow <br />
    //              Ticketing tool!
    //            </Typography>

    //            <CountdownTimer
    //              onClick={handleRedirect}
    //              variant="h6"
    //              sx={{ mt: "20px", ml: "120px" }}
    //            >
    //              In ({timer})s
    //            </CountdownTimer>
    //          </DialogContent>
    //        </Dialog>
    //      </Box>
    //    ),
    //  },
    {
      field: "status",
      headerName: "STATUS",
      headerAlign: "center",
      width: 180,
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography sx={{ textTransform: "uppercase" }}>
            {getTicketStatusName(params?.row?.status || "-")}
          </Typography>
        </Box>
      ),
    },
    //  {
    //    field: "updated_by",
    //    headerName: "CHANGED BY",
    //    headerAlign: "center",
    //    width: 150,
    //    renderCell: (params) => {
    //      const userId = params?.value;
    //      const userDetails = userDetailsMap.get(userId) || userId

    //      return (
    //        <Box sx={{ textAlign: "center" }}>
    //          {userDetails.fullname || userId} {/* Display fullname or fallback to userId */}
    //        </Box>
    //      );
    //    },
    //  },

    //  {
    //    field: "reason",
    //    headerName: "REASON",
    //    headerAlign: "center",
    //    width: 140,
    //  },
    {
      field: "swm_details",
      headerName: "SOFTWARE MODULE",
      headerAlign: "left",
      width: 250,
      renderCell: (params) => {
        const { swm_details } = params?.row;
        return (
          <Box sx={{ textAlign: "left", fontSize: "14px" }}>
            <Box>
              <strong>Name:</strong> {swm_details?.name || "-"}
            </Box>
            <Box>
              <strong>Version:</strong> {swm_details?.version || "-"}
            </Box>
            <Box>
              <strong>Description:</strong> {swm_details?.description || "-"}
            </Box>
            <Box sx={{ textAlign: "left", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleSWMViewButtonClick(params?.row?.allData)}
                sx={{ ...appTheme.commonBtnStyle, fontSize: "12px" }}
                size="small"
              >
                View more{" >>"}
              </Button>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "ls_lr_details",
      headerName: "LEGAL DETAILS",
      headerAlign: "left",
      width: 250,

      renderCell: (params) => {
        const { lr_details, ls_details } = params?.row;
        return (
          <Box sx={{ textAlign: "left", fontSize: "14px" }}>
            <Box>
              <strong>Chapter:</strong> {lr_details?.chapter || "-"}
            </Box>
            <Box>
              <strong>Requirement Number:</strong> {lr_details?.req_number || "-"}
            </Box>
            <Box>
               <strong>Standard Number:</strong> {ls_details?.standard_number || "-"}
             </Box>
             <Box>
               <strong>Revision:</strong> {ls_details?.revision || "-"}
             </Box>
             {/* <Box>
               <strong>Standard Name:</strong> {ls_details?.name || "-"}
             </Box> */}
            {/* <Box>
              <strong>Description:</strong>
              <ExpandableDescription
                description={lr_details?.description || "-"}
              />
            </Box> */}
            <Box sx={{ textAlign: "left", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleLEGALViewButtonClick(params?.row?.allData)}
                sx={{ ...appTheme.commonBtnStyle, fontSize: "12px" }}
                size="small"
              >
                View more{" >>"}
              </Button>
            </Box>
          </Box>
        );
      },
    },

    // {
    //   field: "ls_details",
    //   headerName: "LEGAL STANDARD",
    //   headerAlign: "center",
    //   width: 350,
    //   renderCell: (params) => {
    //     const { ls_details } = params?.row;
    //     return (
    //       <Box sx={{ textAlign: "justify" }}>
    //         <Box>
    //           <strong>Number:</strong> {ls_details?.standard_number || "-"}
    //         </Box>
    //         <Box>
    //           <strong>Revision:</strong> {ls_details?.revision || "-"}
    //         </Box>
    //         <Box>
    //           <strong>Name:</strong> {ls_details?.name || "-"}
    //         </Box>
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "ee_details",
      headerName: "EE COMPONENT",
      headerAlign: "left",
      width: 250,

      renderCell: (params) => {
        const { ee_details } = params?.row;
        return (
          <Box sx={{ textAlign: "left", fontSize: "14px" }}>
            <Box>
              <strong>Supplier Name:</strong> {ee_details?.supplier_name || "-"}
            </Box>
            <Box>
              <strong>Assembly Number:</strong>{" "}
              {ee_details?.assembly_number || "-"}
            </Box>
            <Box>
              <strong>Version:</strong> {ee_details?.version || "-"}
            </Box>

            <Box>
              <strong>Description:</strong> {ee_details?.description || "-"}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      headerAlign: "left",
      width: 300,
      renderCell: (params) => <DescriptionCell value={params?.value || "-"} />,
    },
    {
      field: "users",
      headerName: "USERS",
      headerAlign: "center",
      width: 300,
      renderCell: (params) => {
        const techServiceUsers = params?.row?.tech_service_users || [];
        const oemUsers = params?.row?.oem_users || [];

        const defaultUserDetails = {
          fullname: "User removed",
          email: "",
          role_details: {},
        };

        const allUserIds = [...techServiceUsers, ...oemUsers];

        const techServiceUserOptions = techServiceUsers.map((userId) => ({
          id: userId,
          ...(userDetailsMap.get(userId) || defaultUserDetails),
        }));

        const oemUserOptions = oemUsers.map((userId) => ({
          id: userId,
          ...(userDetailsMap.get(userId) || defaultUserDetails),
        }));

        const techServiceDefault =
          techServiceUserOptions.length > 0
            ? techServiceUserOptions[0]?.id
            : "";
        const oemDefault =
          oemUserOptions.length > 0 ? oemUserOptions[0]?.id : "";

        return (
          <FormControl fullWidth>
            {techServiceUserOptions.length > 0 && (
              <>
                <label style={{ fontWeight: "bold", fontSize: "14px" }}>TECH SERVICE USER</label>
                <Select
                  value={techServiceDefault}
                  displayEmpty
                  sx={{ height: "40px", fontSize: "15px" }}
                  renderValue={(selected) => {
                    const selectedUser = techServiceUserOptions.find(
                      (user) => user.id === selected
                    );
                    return selectedUser
                      ? selectedUser.fullname
                      : "No Tech Service User";
                  }}
                >
                  {techServiceUserOptions.map((user) => (
                    <MenuItem  sx={{ fontSize: "15px" }} key={`tech-${user?.id}`} value={user?.id}>
                      {user?.fullname}
                      <br />
                      {user?.email}
                      <br />
                      {user?.role_details?.role_name}
                      <br />
                      {user?.role_details?.department}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {oemUserOptions.length > 0 && (
              <>
                <label
                  style={{
                    fontWeight: "bold", fontSize: "14px",
                    marginTop: techServiceUserOptions.length > 0 ? "10px" : "0",
                  }}
                >
                  OEM USER
                </label>
                <Select
                  value={oemDefault}
                  displayEmpty
                  sx={{ height: "40px", fontSize: "15px" }}
                  renderValue={(selected) => {
                    const selectedUser = oemUserOptions.find(
                      (user) => user.id === selected
                    );
                    return selectedUser ? selectedUser.fullname : "No OEM User";
                  }}
                >
                  {oemUserOptions.map((user) => (
                    <MenuItem sx={{ fontSize: "15px" }} key={`oem-${user?.id}`} value={user?.id}>
                      {user?.fullname}
                      <br />
                      {user?.email}
                      <br />
                      {user?.role_details?.role_name}
                      <br />
                      {user?.role_details?.department}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}

            {techServiceUserOptions.length === 0 &&
              oemUserOptions.length === 0 && (
                <Typography>No Users Available</Typography>
              )}
          </FormControl>
        );
      },
    },
    {
      field: "created_by",
      headerName: "CREATED BY",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => {
        const userId = params?.value;
        const userDetails = userDetailsMap.get(userId) || userId;

        return (
          <Box sx={{ textAlign: "center" }}>
            {userDetails.fullname || userId}{" "}
            {/* Display fullname or fallback to userId */}
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "CREATED AT",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center" }}>{params?.value || "-"}</Box>
      ),
    },

    //  {
    //    field: "updated_at",
    //    headerName: "CHANGED AT",
    //    headerAlign: "center",
    //    width: 200,
    //    renderCell: (params) => (
    //      <Box sx={{ textAlign: "center" }}>{params?.value || "-"}</Box>
    //    ),
    //  },
  ];


  // Handle button click to open drawer with ticket details
  const handleSWMViewButtonClick = (item) => {
    const modules = item?.dependencies?.software_modules || [];
    const vehicleFunctions = item?.swm_details?.vehicle_functions;

    // Map the modules to include both module details and their corresponding ee_details
    const items2 = modules?.map((module) => ({
      swm_id: module?.swm_id,
      name: module?.name,
      version: module?.version,
      description: module?.description,
      ee_details: module?.ee_details || [], // Include ee_details in each module
    }));

    const eeDetails = modules?.flatMap((module) => module?.ee_details) || [];

    setDialogData({
      heading1: `Vehicle Functions`,
      items1:
        vehicleFunctions?.map((vehicleFunction) => ({
          name: vehicleFunction?.name,
          abbreviation: vehicleFunction?.abbreviation,
        })) || [],
      heading2: `Dependent Software Modules`,
      items2: items2, // Use the combined items
      heading3: `EE Details`,
      items3: eeDetails.map((ee) => ({
        ee_id: ee?.ee_id,
        assembly_number: ee?.assembly_number,
        description: ee?.description,
        supplier_name: ee?.supplier_name,
        version: ee?.version,
      })),
    });

    toggleDialog(true)();
  };

  const handleLEGALViewButtonClick = (item) => {
    const lr_details = item?.lr_details || {}; 
    const ls_details = item?.ls_details || {}; 
  
    console.log("lr_details", lr_details);
    console.log("ls_details", ls_details);
  
    // Directly map lr_details to items2
    const items2 = [{
      lr_id: lr_details.lr_id,
      chapter: lr_details.chapter,
      description: lr_details.description,
      req_number: lr_details.req_number,
    }];
  
    // Create items3 from ls_details
    const items3 = [{
      ls_id: ls_details.ls_id,
      standard_number: ls_details.standard_number,
      name: ls_details.name,
      revision: ls_details.revision,
      description: ls_details.description,
      chapters: ls_details.chapters,
    }];
  
    setLegalDialogData({
      heading2: `Legal Requirement`,
      items2: items2,
      items3: items3, // Optionally include ls_details if needed
    });
  
    toggleLegalDialog(true)();
  };
    

  return (
    <Box sx={styles.mainBox}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Box sx={{ marginBottom: 2, alignItems: "center" }}>
          <SearchSelectTextField
            size={"small"}
            label={"Search Project"}
            placeholder={"Search Project"}
            noOptionsText={"No project found"}
            optionsList={projectOptionsList}
            selectedValue={selectedProject}
            setSelectedValue={setSelectedProject}
            txtFldStyle={{
              mt: { sm: 2, md: 1 },
              width: "215px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box sx={{ marginLeft: 35 }}>
          <Button
            variant="contained"
            sx={{ ...appTheme.commonBtnStyle }}
            size="small"
            onClick={handleDeleteDraftTicket}
            disabled={selectedIds.length === 0}
          >
            Delete Ticket
          </Button>
        </Box>

        <Box sx={{ marginLeft: 2 }}>
          <Button
            variant="contained"
            sx={{ ...appTheme.commonBtnStyle }}
            size="small"
            onClick={handleReCreateTicket}
            disabled={selectedIds.length === 0}
          >
            Re-create Ticket
          </Button>
        </Box>
      </Box>

      <Box>
        <Card sx={styles.card}>
          <CardContent sx={styles.cardContent}>
            {[
              {
                key: "Project Name",
                value: selectedProjectDetails?.name || "-",
              },
              {
                key: "Architecture Name",
                value:
                  selectedProjectDetails?.architecture_name.toLowerCase() ||
                  "-",
              },
              {
                key: "Trade Mark",
                value: selectedProjectDetails?.trade_mark || "-",
              },
              {
                key: "Vehicle Platform",
                value: selectedProjectDetails?.vehicle_platform || "-",
              },
              {
                key: " Status",
                value:
                  getProjectStatusName(selectedProjectDetails?.status) || "-",
              },
            ]?.map((data) => (
              <Box key={data.key} sx={styles.dataBox}>
                <Typography sx={styles.dataTypographyCardKey}>
                  {data.key}
                </Typography>
                <strong>:</strong>
                <Typography
                  sx={{
                    ...styles.dataTypographyCardValue,
                    textTransform: "uppercase",
                  }}
                >
                  {data.value}
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Box>

      {/* Add/Filter/Sorting buttons */}
      <Box sx={styles.btnsContainer}>
        <CustomFiltersN
          anchorEl={anchorElFilter}
          open={openFilter}
          handleClose={handleFilterClose}
          mainOptionsList={[
            // { value: "ticket_id", label: "Ticket ID", type: "search" },
            // { value: "ticket_id", label: "Ticket ID", type: "search" },
            // { value: "intr_id", label: "Intersection ID" },
            {
              value: "status",
              label: "Status",
              type: "select",
              value_options_list:
                ticketStatusData?.length > 0 ? [...ticketStatusData] : [],
            },
            // { value: "swm_id", label: "SWM ID" },
            // { value: "lr_id", label: "LR ID" },
            // { value: "ls_id", label: "LS ID" },
          ]}
          selectedMainOption={selectedSearchField}
          selectedMainOptionValue={searchFieldValue}
          handleFilterClick={handleFilterClick}
          onReset={handleFilterResetAll}
          onApplyNow={handleFilterApplyNow}
        />

        <CustomSorting
          anchorEl={anchorElSort}
          open={openSort}
          handleClose={handleSortClose}
          sortOptionsList={[
            { value: "created_at", label: "Created At" },
            { value: "updated_at", label: "Updated At" },
            { value: "intr_id", label: "Intersection ID" },
            // { value: "ticket_id", label: "Ticket ID" },
            // { value: "ticket_id", label: "Ticket ID" },
            { value: "status", label: "Status" },
          ]}
          selectedSortColumn={selectedSortColumn}
          selectedSortOrder={selectedSortOrder}
          handleSortClick={handleSortClick}
          onReset={handleSortResetAll}
          onApplyNow={(column, order) => handleSortApplyNow(column, order)}
        />
      </Box>

      {/* Container for the Table */}
      <Box sx={styles.box}>
        {tableRowsData && tableRowsData.length > 0 ? (
          <Paper sx={styles.paper}>
          <CustomDataGridTableB
            tableRowsData={tableRowsData}
            columns={COLUMNS}
            rowCount={tableRowsData.length}
            isMultiLine={true}
            handleTableRowClick={() => null}
          />
          <Paper sx={styles.paginationPaper}>
            {/* Pagination with select page size */}
            <CustomPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              hasMore={hasMore}
            />
          </Paper>
        </Paper>
        ):(
                <Typography sx={{ padding: 2, textAlign: 'center' }}>
                No tickets available.
              </Typography>
        )}
        
      </Box>

      {/* Container for Dialog to view more about ticket */}
      <Dialog
        open={isDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={toggleDialog(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "350px",
            boxSizing: "border-box",
            padding: 0,
          },
        }}
      >
        <Box sx={styles.drawerContainer}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Software Module
          </Typography>
          <Divider sx={styles.divider} />

          {/* Close Button */}
          <IconButton onClick={toggleDialog(false)} sx={styles.closeButton}>
            <CloseIcon />
          </IconButton>

          {/* Vehicle Functions */}
          <Box sx={styles.drawerWrap}>
            <Paper sx={styles.paperDrawer}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Vehicle Function
              </Typography>
              <Divider sx={styles.divider} />

              {dialogData?.items1?.map((vehicleFunction, index) => (
                <Paper key={index} sx={styles.cardDrawer}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      display: "flex",
                    }}
                  >
                    <strong>{vehicleFunction.abbreviation}</strong>:{" "}
                    {vehicleFunction.name}
                  </Typography>
                </Paper>
              ))}
            </Paper>
          </Box>

          {/* Software Modules */}
          <Box sx={styles.drawerWrap}>
            {dialogData?.heading2 && (
              <Paper sx={styles.paperDrawer}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Dependent Software Modules
                </Typography>
                <Divider sx={styles.divider} />
                <Box>
                  {dialogData?.items2.map((module, index) => (
                    <Paper key={index} sx={styles.cardDrawer}>
                      <Box
                        sx={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {/* Software Modules */}
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "15px",
                              textDecoration: "underline",
                            }}
                          >
                            Software Module
                          </Typography>
                          <Box sx={{ gap: "10px" }}>
                            {[
                              { header: "ID", value: module?.swm_id },
                              { header: "Name", value: module?.name },
                              { header: "Version", value: module?.version },
                              {
                                header: "Description",
                                value: module?.description,
                              },
                            ].map((det, key) => (
                              <Typography key={key} sx={{ fontSize: "13px" }}>
                                <strong>{det?.header}:</strong> {det?.value}
                              </Typography>
                            ))}
                          </Box>
                        </Box>

                        {/* EE Component */}
                        {module?.ee_details && module.ee_details.length > 0 && (
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "15px",
                                paddingTop: "10px",
                                textDecoration: "underline",
                              }}
                            >
                              EE Component
                            </Typography>
                            {module.ee_details.map((ee, eeIndex) => (
                              <Box key={eeIndex} sx={{ gap: "10px" }}>
                                {[
                                  { header: "Name", value: ee?.description },
                                  { header: "Version", value: ee?.version },
                                  {
                                    header: "Assembly Number",
                                    value: ee?.assembly_number,
                                  },
                                  {
                                    header: "Supplier Name",
                                    value: ee?.supplier_name,
                                  },
                                ].map((det, key) => (
                                  <Typography
                                    key={key}
                                    sx={{ fontSize: "13px" }}
                                  >
                                    <strong>{det?.header}:</strong> {det?.value}
                                  </Typography>
                                ))}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </Paper>
            )}
          </Box>
        </Box>

        {/* <DialogActions>
    <Button onClick={toggleDialog(false)} sx={{ ...appTheme.commonBtnStyle }}>
      Close
    </Button>
  </DialogActions> */}
      </Dialog>

            {/* Container for Dialog to view more about ticket */}
<Dialog
  open={isLegalDialogOpen}
  TransitionComponent={Transition}
  keepMounted
  onClose={toggleLegalDialog(false)}
  sx={{
    "& .MuiDrawer-paper": {
      width: "100%",
      maxWidth: "350px",
      boxSizing: "border-box",
      padding: 0,
    },
  }}
>
  <Box sx={styles.drawerContainer}>
    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
      LEGAL DETAILS
    </Typography>
    <Divider sx={styles.divider} />

    {/* Close Button */}
    <IconButton onClick={toggleLegalDialog(false)} sx={styles.closeButton}>
      <CloseIcon />
    </IconButton>

    {/* Legal Requirements */}
    <Box sx={styles.drawerWrap}>
      {legalDialogData?.heading2 && (
        <Paper sx={styles.paperDrawer}>
          <Box>
            {legalDialogData?.items2.map((lr, index) => (
              <Paper key={index} sx={styles.cardDrawer}>
                <Box
                  sx={{
                    border: "1px solid black",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "15px",
                      textDecoration: "underline",
                    }}
                  >
                    Legal Requirements
                  </Typography>
                  <Box sx={{ gap: "10px" }}>
                    {[
                      { header: "ID", value: lr?.lr_id },
                      { header: "Chapter", value: lr?.chapter },
                      {
                        header: "Requirement Number",
                        value: lr?.req_number,
                      },
                      {
                        header: "Description",
                        value: lr?.description,
                      },
                    ].map((det, key) => (
                      <Typography key={key} sx={{ fontSize: "13px" }}>
                        <strong>{det?.header}:</strong> {det?.value}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Paper>
            ))}
          </Box>
        </Paper>
      )}
    </Box>

    {/* Legal Standards */}
    <Box sx={styles.drawerWrap}>
      {legalDialogData?.items3 && (
        <Paper sx={styles.paperDrawer}>
          <Box>
            {legalDialogData?.items3.map((ls, index) => (
              <Paper key={index} sx={styles.cardDrawer}>
                <Box
                  sx={{
                    border: "1px solid black",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "15px",
                      textDecoration: "underline",
                    }}
                  >
                    Legal Standard
                  </Typography>
                  <Box sx={{ gap: "10px" }}>
                    {[
                      { header: "ID", value: ls?.ls_id },
                      { header: "Standard Number", value: ls?.standard_number },
                      { header: "Name", value: ls?.name },
                      { header: "Revision", value: ls?.revision },
                      { header: "Description", value: ls?.description },
                      { header: "Chapters", value: ls?.chapters.join(", ") }, // Display chapters
                    ].map((det, key) => (
                      <Typography key={key} sx={{ fontSize: "13px" }}>
                        <strong>{det?.header}:</strong> {det?.value}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Paper>
            ))}
          </Box>
        </Paper>
      )}
    </Box>
  </Box>
</Dialog>
    </Box>
  );
}

export default IntersDelTicket;

//Function to minimize the length of the description for legal requirement
const ExpandableDescription = ({ description }) => {
  const maxLength = 120;
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle the description view
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const displayText =
    isExpanded || !description || description.length <= maxLength
      ? description
      : `${description.substring(0, maxLength)}...`;

  return (
    <Box sx={{ textAlign: "justify" }}>
      <Typography variant="body2">
        {displayText}
        {description && description.length > maxLength && (
          <Button onClick={handleToggle} sx={{ textTransform: "none", p: 0 }}>
            {isExpanded ? "Show Less" : "Show More"}
          </Button>
        )}
      </Typography>
    </Box>
  );
};

//Function to minimize the length of the project description
const DescriptionCell = ({ value }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 200;
  // Function to toggle the description view
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  const displayText =
    isExpanded || !value || value.length <= maxLength
      ? value
      : `${value.substring(0, maxLength)}...`;
  return (
    <Box sx={{ textAlign: "justify", fontSize: "14px" }}>
      <Typography variant="body2">
        {displayText || "No Description for the selected project"}
        {value && value.length > maxLength && (
          <Button onClick={handleToggle} sx={{ textTransform: "none", p: 0 }}>
            {isExpanded ? "Show Less" : "Show More"}
          </Button>
        )}
      </Typography>
    </Box>
  );
};

//Constants to handle ticket status

// snowTicketStatus: [
//   { key: "Draft", value: 101 },
//   { key: "New", value: 100 },
//   { key: "Artifact Confirmation", value: 200 },
//   { key: "Evidence Submission", value: 300 },
//   { key: "Evidence Review", value: 400 },
//   { key: "Testing at Technical Service", value: 500 },
//   { key: "Resolved", value: 600 },
// ],

const ticketStatus = {
  100: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#000000",
        background: "none",
        color: "#000000",
        fontSize: "12px",
      }}
      label={"NEW"}
      size="medium"
    />
  ),
  101: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"DRAFT"}
      size="medium"
    />
  ),
  200: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"ARTIFACT CONFIRMATION"}
      size="medium"
    />
  ),
  300: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"EVIDENCE SUBMISSION"}
      size="medium"
    />
  ),
  400: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"EVIDENCE REVIEW"}
      size="medium"
    />
  ),
  500: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        background: "none",
        // backgroundColor: "#0076a8",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"TESTING AT TECHNICAL SERVICE"}
      size="medium"
    />
  ),
  600: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#26890D",
        background: "none",
        color: "#26890D",
        fontSize: "12px",
      }}
      label={"RESOLVED"}
      size="medium"
    />
  ),
};

//Constants to handle project Status
const projectStatus = {
  100: (
    <Chip
      sx={{
        boxShadow: "3",
        fontWeight: "bold",
        borderRadius: "5px",
        backgroundColor: "#0076a8",
        color: "white",
      }}
      label={"Active Pre SOP"}
      size="medium"
    />
  ),
  200: (
    <Chip
      sx={{
        boxShadow: "3",
        fontWeight: "bold",
        borderRadius: "5px",
        backgroundColor: "#0076a8",
        color: "white",
      }}
      label={"Active Post SOP"}
      size="medium"
    />
  ),
  300: (
    <Chip
      sx={{
        boxShadow: "3",
        fontWeight: "bold",
        borderRadius: "5px",
        backgroundColor: "#0076a8",
        color: "white",
      }}
      label={"Deactivated"}
      size="medium"
    />
  ),
  500: (
    <Chip
      sx={{
        boxShadow: "3",
        fontWeight: "bold",
        borderRadius: "5px",
        backgroundColor: "#0076a8",
        color: "white",
      }}
      label={"Completed"}
      size="medium"
    />
  ),
};

// Styles for the page
const styles = {
  filter: {
    position: "relative",
    top: "4px",
    right: "8px",
  },
  mainBox: {
    pt: 0,
    boxShadow: 3,
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    height: "100vh",
    pr: 2,
    pl: 2,
  },
  box: {
    height: "100vh",
    width: "100%",
  },
  paper: {
    boxShadow: 3,
    height: "62%",
    width: "auto",
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "400px",
    padding: 2,
    position: "relative",
  },
  closeButton: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
  },
  divider: {
    border: "1px solid lightgrey",
    margin: "8px 0",
    marginY: 1,
  },
  paperDrawer: {
    marginBottom: 2,
    padding: 2,
    boxShadow: 10,
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
  },
  drawerWrap: {
    marginTop: 2,
  },

  dataTypographyKey: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "110px",
  },
  dataTypographyValue: {
    marginLeft: 1,
    fontSize: "14px",
    textAlign: "left",
  },
  dataBox: {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    flexWrap: "wrap",
    gap: 1,
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
  },
  card: {
    boxShadow: 3,
    width: "100%",
    height: "auto",
    marginBottom: 1,
    padding: 2,
  },
  cardContent: {
    display: "flex",
    height: "40px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dataTypography: {
    fontWeight: "bold",
    fontSize: "13px",
    textAlign: "left",
    minWidth: "120px",
  },
  dataTypographyCardKey: {
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    minWidth: "50px",
  },
  dataTypographyCardValue: {
    fontSize: "15px",
    textAlign: "left",
    minWidth: "50px",
  },
  btnsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 1,
    gap: 1,
  },
  cardDrawer: { margin: 1, padding: 2, boxShadow: 3 },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
  },
};
