/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-09-2024
 * @description: To give co-owner/editor/viewer access to the project/zones/ee component/software module
 */
import {
  Box,
  Button,
  ButtonGroup,
  createTheme,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchSelectTextField } from "../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions, labCar } from "../../redux/actions";
import customConsole from "../../config/customConsole";

import NewRequestTab from "./project-access/NewRequestTab";
import PendingRequestsTab from "./project-access/PendingRequestsTab";
import AccessControlTab from "./project-access/AccessControlTab";

function ProjectAccess() {
  const moduleTypes = { PROJECT: 100, ZONE: 200, EE: 300, SWM: 400 };
  const accessTypes = { COOWNER: 100, EDITOR: 200, VIEWER: 300 };

  const dispatch = useDispatch();

  /* useSelectors */
  const { projectDetails } = useSelector(
    (state) => state?.labCarReducer,
    shallowEqual
  );
  const {
    project_status,
    access_module_types,
    access_request_types,
    access_request_status,
  } = useSelector((state) => state?.settingsReducer?.metaData, shallowEqual);

  /* Project-related state variables */
  const [projectOptionsList, setProjectOptionsList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);

  // Project access tabs state
  const [selectedPaTab, setSelectedPaTab] = useState(0);

  /* UseEffects */
  useEffect(() => {
    // Get the project details
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
  }, []);

  useEffect(() => {
    // Project options list
    let filteredProjectOptionsList = projectDetails
      ?.map((el) => {
        return {
          value: el?.project_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setProjectOptionsList(filteredProjectOptionsList);

    /* Select first project by default */
    if (filteredProjectOptionsList.length) {
      setSelectedProject(filteredProjectOptionsList[0]);
    }
  }, [projectDetails]);

  useEffect(() => {
    // Make the stated to default, when the project is changed
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Update selected project details based on the selected project */
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el?.project_id == selectedProject?.value
      );

      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }
  }, [selectedProject]);

  useEffect(() => {}, [selectedProjectDetails]);

  useEffect(() => {
    customConsole({ projectDetails });
    customConsole({ projectOptionsList });
    customConsole({ selectedProject });
    customConsole({ selectedProjectDetails });
    customConsole({ selectedPaTab });
  }, [
    projectDetails,
    projectOptionsList,
    selectedProject,
    selectedProjectDetails,
    selectedPaTab,
  ]);

  return (
    <Box sx={{ mb: 7 }}>
      {/* Search Project */}
      <Box sx={styles.spContainer}>
        <SearchSelectTextField
          size={"small"}
          label={"Search Project"}
          placeholder={"Search Project"}
          noOptionsText={"No project found"}
          optionsList={projectOptionsList}
          selectedValue={selectedProject}
          setSelectedValue={setSelectedProject}
          txtFldStyle={styles.searchSelectTextField}
        />
      </Box>

      {/* Project access tabs */}
      <Box sx={styles.tabsContainer}>
        <ButtonGroup>
          {["New Request", "Pending Requests", "Access Control"].map(
            (label, index) => (
              <Button
                sx={{
                  color: "black",
                  borderRadius: 0,
                  borderColor: "#333",
                  backgroundColor:
                    selectedPaTab === index ? "#26890D" : "transparent",
                  "&:hover": {
                    borderColor: "#333",
                    backgroundColor:
                      selectedPaTab == index ? "#37b518" : "#f0f0f0",
                  },
                }}
                onClick={() => setSelectedPaTab(index)}
              >
                {label}
              </Button>
            )
          )}
        </ButtonGroup>
      </Box>

      {(() => {
        switch (selectedPaTab) {
          case 0:
            return (
              <NewRequestTab
                selectedProjectDetails={selectedProjectDetails}
                projectStatus={project_status}
                moduleTypes={moduleTypes}
                accessTypes={accessTypes}
              />
            );
          case 1:
            return (
              <PendingRequestsTab
                selectedProjectDetails={selectedProjectDetails}
                moduleTypes={moduleTypes}
                accessModuleTypes={access_module_types}
                accessRequestTypes={access_request_types}
                accessRequestStatus={access_request_status}
              />
            );
          case 2:
            return (
              <AccessControlTab
                selectedProjectDetails={selectedProjectDetails}
                projectStatus={project_status}
                moduleTypes={moduleTypes}
                accessTypes={accessTypes}
              />
            );
          default:
            return (
              <NewRequestTab
                selectedProjectDetails={selectedProjectDetails}
                projectStatus={project_status}
                moduleTypes={moduleTypes}
                accessTypes={accessTypes}
              />
            );
        }
      })()}
    </Box>
  );
}

export default ProjectAccess;

// Styles
const styles = {
  spContainer: {
    textAlign: "start",
    display: "flex",
    justifyContent: "center",
    mb: 4,
    mt: -1.4,
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
  tabsContainer: {
    display: "flex",
  },
};
