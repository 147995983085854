/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-09-2024
 * @description: To give co-owner/editor/viewer access to the project/zones/ee component/software module
 */
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import customConsole from "../../config/customConsole";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import labCar from "../../redux/actions/labCar";
import userManagementApis from "../../redux/actions/userManagent";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import helper from "../../utils/helper";
import { CustomButton } from "../../components";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AddIcon from "@mui/icons-material/Add";

function ProjectAccessDrawer(props) {
  const PROJECT_ACCESS = 100;
  const ZONE_ACCESS = 200;
  const EE_ACCESS = 300;
  const SWM_ACCESS = 400;

  const dispatch = useDispatch();

  /* useSelectors */
  const {
    project_status,
    access_module_types,
    access_request_types,
    access_request_status,
  } = useSelector((state) => state?.settingsReducer?.metaData, shallowEqual);

  const [existingAccess, setExistingAccess] = useState({
    name: "",
    version: "",
    description: "",
    architecture: "",
    owner_details: [],
    co_owner_details: [],
    edit_user_details: [],
    view_user_details: [],
  });

  const [enableNotification, setEnableNotification] = useState(true);
  const [selectType, setSelectedType] = useState(1);
  const [selectedCoOwners, setSelectedCoOwners] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedEditors, setSelectedEditors] = useState([]);
  const [selectedViewers, setSelectedViewers] = useState([]);
  const [usersInSystem, setUsersInSystem] = useState([]);

  //Todo: Filter it based on name or role or etc..
  const getUsersInSystem = async () => {
    const apiDetails = userManagementApis.get_users_list_pa;
    delete apiDetails.action_type;
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        show_toast: false,
        params: {
          page_number: 1,
          page_size: 250,
        },
      })
    );
    if (resp.error) {
      setUsersInSystem([]);
    } else {
      setUsersInSystem(resp.data);
    }
  };

  const getProjectEEAndSWMDetails = async () => {
    let requestDetails = {
      show_toast: false,
    };
    if (props.data.access_type === PROJECT_ACCESS) {
      // get the project details
      const apiDetails = labCar.get_project_details_pa;
      delete apiDetails.action_type;
      requestDetails = {
        ...requestDetails,
        ...apiDetails,
        params: {
          project_id: props.data.project_id,
        },
        show_toast: true,
      };
    } else if (props.data.access_type === ZONE_ACCESS) {
      // get the project details
      const apiDetails = labCar.get_project_details_pa;
      delete apiDetails.action_type;
      requestDetails = {
        ...requestDetails,
        ...apiDetails,
        params: {
          project_id: props.data.project_id,
        },
        show_toast: true,
      };
    } else if (props.data.access_type === EE_ACCESS) {
      // Get the EE Componenet details
      const apiDetails = labCar.get_ee_components_pa;
      delete apiDetails.action_type;
      requestDetails = {
        ...requestDetails,
        ...apiDetails,
        params: {
          project_id: props.data.project_id,
          ee_id: props.data.ee_id,
          is_for_req_access: true,
        },
        show_toast: true,
      };
    } else {
      // Get the Software Module details
      const apiDetails = labCar.get_sw_modules_pa;
      delete apiDetails.action_type;
      requestDetails = {
        ...requestDetails,
        ...apiDetails,
        params: {
          project_id: props.data.project_id,
          ee_id: props.data.ee_id,
          swm_id: props.data.swm_id,
          is_for_req_access: true,
        },
        show_toast: true,
      };
    }
    const resp = await dispatch(HandleApiActions(requestDetails));
    customConsole("-- resp----");
    customConsole(resp);
    if (resp.error || !resp.data) {
      setExistingAccess({
        text1: "",
        text2: "",
        text3: "",
        architecture: "",
        project_details: [],
        zone_details: [],
        ee_details: [],
        swm_details: [],
        owner_details: [],
        co_owner_details: [],
        edit_user_details: [],
        view_user_details: [],
      });
      props.handleClose();
    } else {
      setExistingAccess({
        text1:
          props.data.access_type === PROJECT_ACCESS
            ? resp.data[0].name
            : props.data.access_type === ZONE_ACCESS
              ? resp.data[0].project_zones[0]?.zone_name
              : props.data.access_type === EE_ACCESS
                ? resp.data[0].description
                : resp.data[0].name,
        text2:
          props.data.access_type === PROJECT_ACCESS
            ? resp.data[0].architecture_name
            : resp.data[0].version,
        text3:
          props.data.access_type === EE_ACCESS ? resp.data[0].description : "",
        // Module details
        project_details:
          props.data.access_type === PROJECT_ACCESS ? resp.data[0] : [],
        zone_details:
          props.data.access_type === ZONE_ACCESS
            ? resp.data[0]?.project_zones?.find(
              (zone) => zone?.position == props.data?.zone_position
            )
            : null,
        ee_details: props.data.access_type === EE_ACCESS ? resp.data[0] : null,
        swm_details:
          props.data.access_type === SWM_ACCESS ? resp.data[0] : null,
        // Users access
        owner_details:
          props.data.access_type === ZONE_ACCESS
            ? usersInSystem?.filter((user) => user?.user_id === resp.data[0]?.zone_details?.find(zone => zone?.zone?.position === props.data?.zone_position)?.created_by)
            : usersInSystem?.filter((user) => user?.user_id === resp.data[0]?.created_by),
        co_owner_details:
          props.data.access_type === ZONE_ACCESS
            ? usersInSystem?.filter((user) => resp.data[0]?.zone_details?.find(zone => zone?.zone?.position === props.data?.zone_position)?.co_owners?.includes(user?.user_id))
            : usersInSystem?.filter((user) => resp.data[0]?.co_owners?.includes(user?.user_id)),
        edit_user_details:
          props.data.access_type === ZONE_ACCESS
            ? usersInSystem?.filter((user) => resp.data[0]?.zone_details?.find(zone => zone?.zone?.position === props.data?.zone_position)?.edit_users?.includes(user?.user_id))
            : usersInSystem?.filter((user) => resp.data[0]?.edit_users?.includes(user?.user_id)),
        view_user_details:
          props.data.access_type === ZONE_ACCESS
            ? usersInSystem?.filter((user) => resp.data[0]?.zone_details?.find(zone => zone?.zone?.position === props.data?.zone_position)?.view_users?.includes(user?.user_id))
            : usersInSystem?.filter((user) => resp.data[0]?.view_users?.includes(user?.user_id)),
      });
    }
  };
  useEffect(() => {
    customConsole("-- ProjectAccessDrawer --");
    customConsole(props);
    getUsersInSystem();
  }, [props]);
  useEffect(() => {
    if (usersInSystem?.length) {
      getProjectEEAndSWMDetails();
    }
  }, [usersInSystem]);
  const MapUserDataInList = (details) => {
    return details.data.length && !isNaN(details.data[0].role_id) ? (
      <Select
        variant={"standard"}
        sx={{ minWidth: "200px", width: "100%", gap: 1 }}
        defaultValue={details.data[0].email}
      >
        {details.data.map((data, idx) => {
          return (
            <MenuItem
              value={data.email}
              key={idx}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "14px",
                color: "black",
              }}
            >
              {data.fullname}
              <br />
              {data.email}
            </MenuItem>
          );
        })}
      </Select>
    ) : (
      <strong>-</strong>
    );
  };
  const onUserSelection = (details) => {
    customConsole("-- onUserSelection--");
    customConsole(details.target.value);

    customConsole(selectType);
    const newUser = usersInSystem.find(
      (data) => data.user_id === details.target.value
    );
    if (
      !selectedCoOwners.find((data) => data.user_id === details.target.value) &&
      !selectedEditors.find((data) => data.user_id === details.target.value) &&
      !selectedViewers.find((data) => data.user_id === details.target.value)
    ) {
      if (selectType === 1) {
        setSelectedCoOwners([...selectedCoOwners, newUser]);
      } else if (selectType === 2) {
        // Editors
        setSelectedEditors([...selectedEditors, newUser]);
      } else if (selectType === 3) {
        // Editors
        setSelectedViewers([...selectedViewers, newUser]);
      }
    }
  };
  const handleRemoveUserFromSelected = (details, access_type) => {
    customConsole("-- handleRemoveUserFromSelected --");
    customConsole(details);
    customConsole(access_type);
    if (access_type === 1) {
      setSelectedCoOwners(() =>
        selectedCoOwners.filter((data) => data.user_id !== details.user_id)
      );
    } else if (access_type === 2) {
      setSelectedEditors(() =>
        selectedEditors.filter((data) => data.user_id !== details.user_id)
      );
    } else if (access_type === 3) {
      setSelectedViewers(() =>
        selectedViewers.filter((data) => data.user_id !== details.user_id)
      );
    }
  };
  const updateGrantAccess = async () => {
    if (
      selectedCoOwners.length ||
      selectedEditors.length ||
      selectedViewers.length
    ) {
      customConsole("-- updateGrantAccess --");
      const apiDetails = userManagementApis.grant_project_access;
      delete apiDetails.action_type;
      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          show_toast: true,
          params: {
            access_type: props.data.access_type,
            project_id: props.data.project_id,
            zone_position: props.data?.zone_position,
            ee_id: props.data?.ee_id,
            swm_id: props.data?.swm_id,
            co_owners: selectedCoOwners.length
              ? selectedCoOwners.map((data) => data.user_id)
              : undefined,
            edit_users: selectedEditors.length
              ? selectedEditors.map((data) => data.user_id)
              : undefined,
            view_users: selectedViewers.length
              ? selectedViewers.map((data) => data.user_id)
              : undefined,
          },
        })
      );
      if (!resp.error) {
        setSelectedEditors([]);
        setSelectedCoOwners([]);
        setSelectedViewers([]);
        //Hit the API to get the module details
        await getProjectEEAndSWMDetails();
      } else {
        customConsole(" Error while providing the access!");
        customConsole(resp);
      }
    } else {
      toast.warn("Please add users!");
    }
  };
  useEffect(() => {
    customConsole("selectedCoOwners");
    customConsole(selectedCoOwners);
    customConsole(selectedViewers);
    customConsole(selectedEditors);
  }, [selectedCoOwners, selectedViewers, selectedEditors]);

  useEffect(() => {
    if (
      selectedCoOwners.length === 0 ||
      selectedEditors.length === 0 ||
      selectedViewers.length === 0
    ) {
      setSelectedValue("");
    }
  }, [selectedCoOwners, selectedViewers, selectedEditors]);
  return (
    <Drawer open={props.open} anchor={"right"}>
      {existingAccess?.text1?.length ? (
        <Box
          sx={{
            minWidth: { xs: "300px", sm: "440px" },
            maxWidth: { xs: "300px", sm: "440px" },
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                {props.data.access_type === PROJECT_ACCESS
                  ? "Project Access"
                  : props.data.access_type === ZONE_ACCESS
                    ? "Zone Access"
                    : props.data.access_type === EE_ACCESS
                      ? "EE Component Access"
                      : "Software Module Access"}
              </Typography>
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            {/* <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {existingAccess.text1}
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              {existingAccess.text2}
            </Typography>
            {existingAccess.text3.length ? (
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                {existingAccess.text3}
              </Typography>
            ) : null} */}
            {/* Module details */}
            <Box sx={styles.subBox}>
              {props.data.access_type === PROJECT_ACCESS ? (
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "green",
                    }}
                  >
                    Project Details:
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Name: </span>
                    {existingAccess?.project_details?.name || "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Architecture: </span>
                    {existingAccess?.project_details?.architecture_name || "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Status: </span>
                    {helper?.getObjectKeyByValue(
                      project_status,
                      existingAccess?.project_details?.status
                    ) || "-"}
                  </Typography>
                  {/* <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Delivery Market: </span>
                    {existingAccess?.project_details?.delivery_market || "-"}
                  </Typography> */}
                </Box>
              ) : props.data.access_type === ZONE_ACCESS ? (
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "green",
                    }}
                  >
                    Zone Details:
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Name: </span>
                    {existingAccess?.zone_details?.zone_name || "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Position: </span>
                    {existingAccess?.zone_details?.position || "-"}
                  </Typography>
                </Box>
              ) : props.data.access_type === EE_ACCESS ? (
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "green",
                    }}
                  >
                    EE Component Details:
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Version: </span>
                    {existingAccess?.ee_details?.version || "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Supplier: </span>
                    {existingAccess?.ee_details?.supplier_name || "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Description: </span>
                    {existingAccess?.ee_details?.description || "-"}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "green",
                    }}
                  >
                    Software Module Details:
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Name: </span>
                    {existingAccess?.swm_details?.name || "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Version: </span>
                    {existingAccess?.swm_details?.version || "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Summary: </span>
                    {existingAccess?.swm_details?.summary || "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={styles.mdDetailsTxt}>
                    <span style={styles.txtBold}>Description: </span>
                    {existingAccess?.swm_details?.description || "-"}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                  mb: 1.4,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  Notifications:
                </Typography>
                <CustomButton
                  size="small"
                  variant="contained"
                  btnName="Enabled"
                  handleOnClick={() => setEnableNotification(true)}
                  btnStyle={styles.customButton}
                  endIcon={enableNotification ? <CheckBoxIcon /> : null}
                />
                <CustomButton
                  size="small"
                  variant="contained"
                  btnName="Disabled"
                  handleOnClick={() => setEnableNotification(false)}
                  btnStyle={styles.customButton}
                  endIcon={enableNotification ? null : <CheckBoxIcon />}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
          <Box>
            {/* Existing Users */}
            <Box sx={styles.subBox}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "16px", color: "green" }}
              >
                {" "}
                Existing users:
              </Typography>
              {/* Owner */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    minWidth: "70px",
                  }}
                >
                  Owner
                </Typography>
                <strong>:</strong>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {existingAccess.owner_details[0]?.fullname} <br />{" "}
                  {existingAccess.owner_details[0]?.email}
                </Typography>
              </Box>
              {/* Co-owners, editors and viewers */}
              {[
                { key: "Co-Owners", value: existingAccess.co_owner_details },
                { key: "Editors", value: existingAccess.edit_user_details },
                { key: "Viewers", value: existingAccess.view_user_details },
              ].map((user, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        minWidth: "70px",
                      }}
                    >
                      {user.key}
                    </Typography>
                    <strong>:</strong>
                    <MapUserDataInList data={user.value} />
                  </Box>
                );
              })}
            </Box>
            {/* Add New Users */}
            <Box
              sx={{
                ...styles.subBox,
                marginTop: "20px",
                maxHeight: "50vH",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "green",
                    textAlign: "start",
                  }}
                >
                  {" "}
                  Add users:
                </Typography>
                <Box>
                  <CustomButton
                    size="small"
                    variant="contained"
                    btnName="Add"
                    handleOnClick={updateGrantAccess}
                    btnStyle={styles.customButton}
                    startIcon={<AddIcon />}
                  />
                </Box>
              </Box>

              {/* Seleect Ownership type and users */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px dotted black",
                  padding: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  Select ownership type:
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <CustomButton
                    size="small"
                    variant="contained"
                    btnName="Co-Owners"
                    handleOnClick={() => setSelectedType(1)}
                    btnStyle={styles.customButton}
                    endIcon={selectType === 1 ? <CheckBoxIcon /> : null}
                  />
                  <CustomButton
                    size="small"
                    variant="contained"
                    btnName="editors"
                    handleOnClick={() => setSelectedType(2)}
                    btnStyle={styles.customButton}
                    endIcon={selectType === 2 ? <CheckBoxIcon /> : null}
                  />
                  <CustomButton
                    size="small"
                    variant="contained"
                    btnName="Viewers"
                    handleOnClick={() => setSelectedType(3)}
                    btnStyle={styles.customButton}
                    endIcon={selectType === 3 ? <CheckBoxIcon /> : null}
                  />
                </Box>
                <Typography sx={styles.addedUserTextHeader}>
                  Select users:
                </Typography>
                <Select
                  label="Select Users"
                  variant="standard"
                  sx={{ width: "100%" }}
                  onChange={(data) => onUserSelection(data)}
                  value={selectedValue}
                // defaultValue={
                //   usersInSystem.length ? usersInSystem[0].user_id : ""
                // }
                >
                  <InputLabel>Select Users</InputLabel>
                  {usersInSystem.map((data, idx) => {
                    return (
                      <MenuItem key={idx} value={data.user_id || null}>
                        {data.fullname} <br />
                        {data.email}
                        <br />
                        {data?.role_details?.role_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              {/* Selected Users */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "15px",
                  gap: 2,
                }}
              >
                {/* Co-owners */}
                {selectedCoOwners.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        ...styles.addedTextHeader,
                        marginBottom: "5px",
                      }}
                    >
                      Co-Owners :
                    </Typography>
                    <Box sx={styles.addedUsersbox}>
                      {selectedCoOwners.map((data, idx) => {
                        return (
                          <Typography key={idx} sx={styles.addedUserText}>
                            {data.fullname}
                            <IconButton
                              style={styles.userCancelBtn}
                              onClick={() =>
                                handleRemoveUserFromSelected(data, 1)
                              }
                            >
                              X
                            </IconButton>
                            <br />
                            {data.email}
                            <br />
                            {data?.role_details?.role_name}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                )}
                {/* Editors */}
                {selectedEditors.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        ...styles.addedTextHeader,
                        marginBottom: "5px",
                      }}
                    >
                      Editors :
                    </Typography>
                    <Box sx={styles.addedUsersbox}>
                      {selectedEditors.map((data, idx) => {
                        return (
                          <Typography key={idx} sx={styles.addedUserText}>
                            {data.fullname}
                            <span
                              style={styles.addedUserCloseBtn}
                              onClick={() =>
                                handleRemoveUserFromSelected(data, 2)
                              }
                            >
                              X
                            </span>{" "}
                            <br />
                            {data.email}
                            <br />
                            {data?.role_details?.role_name}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                )}
                {/* Viewers */}
                {selectedViewers.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        ...styles.addedTextHeader,
                        marginBottom: "5px",
                      }}
                    >
                      Viewers :
                    </Typography>
                    <Box sx={styles.addedUsersbox}>
                      {selectedViewers.map((data, idx) => {
                        return (
                          <Typography key={idx} sx={styles.addedUserText}>
                            {data.fullname}
                            <span
                              style={styles.addedUserCloseBtn}
                              onClick={() =>
                                handleRemoveUserFromSelected(data, 3)
                              }
                            >
                              X
                            </span>{" "}
                            <br />
                            {data.email}
                            <br />
                            {data.role_name}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>{" "}
          </Box>
        </Box>
      ) : null}
    </Drawer>
  );
}

export default ProjectAccessDrawer;

const styles = {
  addedTextHeader: { fontWeight: "bold", fontSize: "14px" },
  userCancelBtn: {
    fontWeight: "bold",
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    height: "20px",
    width: "20px",
    fontSize: "12px",
    cursor: "pointer",
    marginLeft: "20px",
    "&:hover": {
      color: "black",
      backgroundColor: "red",
    },
  },
  closeBtn: {
    fontWeight: "bold",
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    height: "25px",
    width: "25px",
    fontSize: "18px",
    cursor: "pointer",
    marginLeft: "20px",
    "&:hover": {
      color: "black",
      backgroundColor: "red",
    },
  },
  subBox: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    border: "2px solid green",
    padding: "10px",
    overflow: "auto",
  },
  addedUserTextHeader: {
    fontWeight: "bold",
    fontSize: "14px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  addedUserCloseBtn: {
    fontWeight: "bold",
    color: "white",
    marginLeft: "15px",
    backgroundColor: "red",
    borderRadius: "50%",
    padding: "2px",
    "&:hover": {
      backgroundColor: "black",
      color: "red",
    },
    cursor: "pointer",
  },
  addedUserText: {
    border: "1px solid black",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "12px",
    padding: "10px",
  },
  addedUsersbox: {
    display: "flex",
    flexWrap: "wrap",
    // maxHeight: "150px",
    overflow: "auto",
    gap: "10px",
  },
  commonButtonStyle: {
    backgroundImage:
      "linear-gradient( to bottom, rgb(7, 5, 69), rgb(103, 86, 133))",
    maxHeight: "30px",
    width: "fit-content",
    borderRadius: "20px",
    color: "white",
    "&:hover": {
      backgroundImage: "linear-gradient(#6b8df2,#021636)",
    },
  },
  txtBold: {
    fontWeight: "bold",
  },
  mdDetailsTxt: {
    my: 1.4,
  },
};
