/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-09-2024
 * @description : Shows list of pending requests which are to be approved
 */
import {
  Paper,
  ButtonGroup,
  Button,
  Typography,
  Divider,
  Grid,
  Box,
  IconButton,
  ToggleButton,
  Tooltip,
  DialogContentText,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  CustomConfirmationDialog,
  CustomDataGridTableN,
  CustomPagination,
  CustomTextFieldN,
} from "../../../components";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomFiltersN from "../../../components/tables/CustomFiltersN";
import { HandleApiActions, userManagementApis } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import customConsole from "../../../config/customConsole";
import helper from "../../../utils/helper";
import { convertUtcToTimeZone } from "../../../utils/convertUtcToTimeZone";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";

function PendingRequestsTab({
  moduleTypes,
  selectedProjectDetails,
  accessModuleTypes,
  accessRequestTypes,
  accessRequestStatus,
}) {
  const dispatch = useDispatch();

  // Redux store
  const profileDetails = useSelector((store) => store.profileReducer);

  // request access data
  const [requestAccessList, setRequestAccessList] = useState([]);

  // Table rows data
  const [tableRowsData, setTableRowsData] = useState([]);

  // Is request send
  const [isRequestSend, setIsRequestSend] = useState(true);

  // Reason to reject access
  const [reasonToRejectAccess, setReasonToRejectAccess] = useState("");

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("access_type");
  const [searchFieldValue, setSearchFieldValue] = useState("");

  // Dialogs states
  const [openApproveAccessDialog, setOpenApproveAccessDialog] = useState(false);
  const [selectedApproveAccessData, setSelectedApproveAccessData] =
    useState(null);
  const [openRejectAccessDialog, setOpenRejectAccessDialog] = useState(false);
  const [selectedRejectAccessData, setSelectedRejectAccessData] =
    useState(null);

  // Handle approve access stages
  const handleApproveAccessClick = (params) => {
    setSelectedApproveAccessData(params);

    setOpenApproveAccessDialog(true);
  };
  const handleConfirmApproveAccess = () => {
    approveRequestAccess(selectedApproveAccessData);

    setTimeout(() => {
      getRequestAccessList({
        projectId: selectedProjectDetails?.project_id,
        pageNumber: page,
        pageSize: rowsPerPage,
      });
    }, 500);  // 500ms delay

    setSelectedApproveAccessData(null);

    setOpenApproveAccessDialog(false);
  };
  const handleCancelApproveAccess = () => {
    setSelectedApproveAccessData(null);

    setOpenApproveAccessDialog(false);
  };

  // Handle reject access stages
  const handleRejectAccessClick = (params) => {
    setSelectedRejectAccessData(params);

    setOpenRejectAccessDialog(true);

    setReasonToRejectAccess("");
  };
  const handleConfirmRejectAccess = () => {
    rejectRequestAccess({
      ...selectedRejectAccessData,
      reason: reasonToRejectAccess,
    });

    setTimeout(() => {
      getRequestAccessList({
        projectId: selectedProjectDetails?.project_id,
        pageNumber: page,
        pageSize: rowsPerPage,
      });
    }, 500);  // 500ms delay

    setSelectedRejectAccessData(null);

    setOpenRejectAccessDialog(false);
  };
  const handleCancelRejectAccess = () => {
    setSelectedRejectAccessData(null);

    setOpenRejectAccessDialog(false);

    setReasonToRejectAccess("");
  };

  const getRequestAccessList = async ({ projectId, pageNumber, pageSize }) => {
    let apiDetails = userManagementApis.get_request_access_list;

    customConsole({
      ...apiDetails,
      params: {
        is_sent_requests: isRequestSend,
        project_id: projectId,
        page_number: pageNumber,
        page_size: pageSize,
        ...(searchFieldValue && { [selectedSearchField]: searchFieldValue }),
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          is_sent_requests: isRequestSend,
          project_id: projectId,
          page_number: pageNumber,
          page_size: pageSize,
          ...(searchFieldValue && { [selectedSearchField]: searchFieldValue }),
        },
      })
    );

    if (resp.error) {
      setRequestAccessList([]);
    } else {
      setRequestAccessList(resp.data);
    }
  };

  const approveRequestAccess = async ({
    id,
    approvingAccess,
    selectedProjectId,
    page,
    rowsPerPage,
  }) => {
    let apiDetails = userManagementApis.approve_access;

    customConsole({
      ...apiDetails,
      params: {
        id: Number(id),
        approving_access: approvingAccess,
      },
      show_toast: true,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          id: Number(id),
          approving_access: approvingAccess,
        },
        show_toast: true,
      })
    );

    if (resp.error) {
    } else {
    }
  };

  const rejectRequestAccess = async ({
    id,
    reason,
    selectedProjectId,
    page,
    rowsPerPage,
  }) => {
    let apiDetails = userManagementApis.reject_access;

    customConsole({
      ...apiDetails,
      params: {
        id: Number(id),
        reason: reason,
      },
      show_toast: true,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          id: Number(id),
          reason: reason,
        },
        show_toast: true,
      })
    );

    if (resp.error) {
    } else {
    }
  };

  // Handles the click event for table rows for editing
  const handleTableRowClick = ({ params }) => {};

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  const COLUMNS = [
    {
      field: "sNo",
      headerName: "S.No",
      width: 90,
      filterable: false,
      sortable: false,
    },
    {
      field: "requested_user",
      headerName: "Requested By",
      width: 220,
      renderCell: (params) => (
        <Box>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Name: </span>
            {params?.row?.requested_user?.fullname || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Email: </span>
            {params?.row?.requested_user?.email || "-"}
          </Typography>
          {/* <Typography variant="subtitle2">
            <span style={styles.txtBold}>Ownership: </span>
            {params?.row?.requested_user?.ownership || "-"}
          </Typography> */}
        </Box>
      ),
    },
    {
      field: "module_type_label",
      headerName: "Module Type",
      width: 220,
    },
    {
      field: "module_details",
      headerName: "Module Details",
      width: 220,
      renderCell: (params) =>
        params?.row?.module_type == moduleTypes?.PROJECT ? (
          <Box>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Name: </span>
              {params?.row?.project_details?.name || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Architecture Name: </span>
              {params?.row?.module_details?.project_details
                ?.architecture_name || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Trade Mark: </span>
              {params?.row?.module_details?.project_details?.trade_mark || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Delivery Market: </span>
              {params?.row?.module_details?.project_details?.delivery_market ||
                "-"}
            </Typography>
          </Box>
        ) : params?.row?.module_type == moduleTypes?.ZONE ? (
          <Box>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Name: </span>
              {params?.row?.module_details?.zone_details?.zone?.zone_name ||
                "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Position: </span>
              {params?.row?.module_details?.zone_details?.zone?.position || "-"}
            </Typography>
          </Box>
        ) : params?.row?.module_type == moduleTypes?.EE ? (
          <Box>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Description: </span>
              {params?.row?.module_details?.ee_details?.description || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Version: </span>
              {params?.row?.module_details?.ee_details?.version || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Supplier Name: </span>
              {params?.row?.module_details?.ee_details?.supplier_name || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Assembly Number: </span>
              {params?.row?.module_details?.ee_details?.assembly_number || "-"}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Name: </span>
              {params?.row?.module_details?.swm_details?.name || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Version: </span>
              {params?.row?.module_details?.swm_details?.version || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Description: </span>
              {params?.row?.module_details?.swm_details?.description || "-"}
            </Typography>
          </Box>
        ),
    },
    {
      field: "access_type",
      headerName: "Access Type",
      width: 220,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2">
            {params?.row?.access_type}
          </Typography>
          {/* <IconButton
            size="small"
            color="primary"
            sx={{ marginLeft: 0.4 }}
            onClick={() => {}}
          >
            <EditIcon fontSize="small" />
          </IconButton> */}
        </Box>
      ),
    },
    {
      field: "request_reason",
      headerName: "Reason",
      width: 220,
    },
    {
      field: "created_at",
      headerName: "Requested At",
      width: 220,
    },
    {
      field: "approved_user",
      headerName: "Approved By",
      width: 220,
      renderCell: (params) => (
        <Box>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Name: </span>
            {params?.row?.approved_user?.fullname || "-"}
          </Typography>
          <Typography variant="subtitle2">
            <span style={styles.txtBold}>Email: </span>
            {params?.row?.approved_user?.email || "-"}
          </Typography>
        </Box>
      ),
    },
    ...(isRequestSend
      ? [
          {
            field: "status",
            headerName: "Status",
            width: 220,
          },
        ]
      : []),
    ...(!isRequestSend
      ? [
          {
            field: "action",
            headerName: "Action",
            width: 220,
            renderCell: (params) => (
              <Box
              // sx={{
              //   display: "flex",
              //   flexDirection: "column",
              //   alignItems: "flex-start",
              // }}
              >
                {/* <Box sx={{ display: "flex", alignItems: "center" }}> */}
                <IconButton
                  size="small"
                  color="success"
                  onClick={() =>
                    handleApproveAccessClick({
                      id: params?.row?.id,
                      approvingAccess: params?.row?.requested_access,
                    })
                  }
                >
                  <CheckCircleIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() =>
                    handleRejectAccessClick({
                      id: params?.row?.id,
                    })
                  }
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            ),
          },
        ]
      : []),
  ];

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);

    setPage(1); // Reset to the first page when page size changes

    handleFilterClose();
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("access_type", "");
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  /* UseEffects */
  useEffect(() => {
    // Get the request access list
    if (selectedProjectDetails?.project_id) {
      getRequestAccessList({
        projectId: selectedProjectDetails?.project_id,
        pageNumber: page,
        pageSize: rowsPerPage,
      });
    }
  }, [
    selectedProjectDetails,
    page,
    rowsPerPage,
    selectedSearchField,
    searchFieldValue,
    isRequestSend,
  ]);

  useEffect(() => {
    // Handle pagination hasMore
    if (requestAccessList?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    let filteredRequestAccessList = requestAccessList
    ?.filter((el) => {
      // If is request send and the status is not pending(100)
      if (!isRequestSend && el?.status !== 100) {
        return false;
      }
      return true;
    })
    ?.map((rc, index) => {
      return {
        ...rc,
        sNo: (page - 1) * rowsPerPage + index + 1,
        requested_user: rc?.requested_user?.length && rc?.requested_user[0],
        module_type_label:
          helper?.getValueByKey(accessModuleTypes, rc?.module_type) || "-",
        module_details: {
          project_details:
            rc?.project_details?.length && rc?.project_details[0],
          zone_details: rc?.zone_details?.length && rc?.zone_details[0],
          ee_details: rc?.ee_details?.length && rc?.ee_details[0],
          swm_details: rc?.swm_details?.length && rc?.swm_details[0],
        },
        access_type:
          helper?.getValueByKey(accessRequestTypes, rc?.requested_access) ||
          "-",
        created_at: convertUtcToTimeZone(
          rc.created_at,
          profileDetails?.profile?.profile?.region
        ),
        approved_user: rc?.approved_user?.length && rc?.approved_user[0],
        status: helper?.getValueByKey(accessRequestStatus, rc?.status) || "-",
      };
    });
    setTableRowsData(filteredRequestAccessList);
  }, [requestAccessList]);

  return (
    <Box sx={{ mb: 12 }}>
      <Box sx={styles.filterBtnContainer}>
        <CustomFiltersN
          anchorEl={anchorElFilter}
          open={openFilter}
          handleClose={handleFilterClose}
          mainOptionsList={[
            {
              value: "access_type",
              label: "Access Type",
              type: "select",
              value_options_list:
                helper?.convertKeyValueToLabel(accessRequestTypes) || [],
            },
            {
              value: "module_type",
              label: "Module Type",
              type: "select",
              value_options_list:
                helper?.convertKeyValueToLabelFiltered(accessModuleTypes, [
                  100,
                  ...(selectedProjectDetails?.is_zonal ? [] : [200]),
                ]) || [],
            },
            {
              value: "status",
              label: "Status",
              type: "select",
              value_options_list:
                helper?.convertKeyValueToLabel(accessRequestStatus) || [],
            },
          ]}
          selectedMainOption={selectedSearchField}
          selectedMainOptionValue={searchFieldValue}
          handleFilterClick={handleFilterClick}
          onReset={handleFilterResetAll}
          onApplyNow={handleFilterApplyNow}
        />
        <ToggleButton
          value="check"
          selected={isRequestSend}
          onChange={() => setIsRequestSend(!isRequestSend)}
          style={{
            width: "25px",
            height: "25px",
            backgroundColor: isRequestSend ? "#4CAF50" : "#F44336",
            color: "white",
          }}
        >
          {isRequestSend ? (
            <Tooltip title={"Send Access Request"}>
              <CallMadeIcon sx={{ backgroundColor: "#26890D" }} />
            </Tooltip>
          ) : (
            <Tooltip title={"Received Access Request"}>
              <CallReceivedIcon sx={{ backgroundColor: "#26890D" }} />
            </Tooltip>
          )}
        </ToggleButton>
      </Box>
      <Paper elevation={4} sx={styles.prTableContainer}>
        <CustomDataGridTableN
          tableRowsData={tableRowsData}
          columns={COLUMNS}
          handleTableRowClick={handleTableRowClick}
          isMultiLine={true}
        />
        <Paper elevation={4} sx={styles.paginationPaper}>
          {/* Pagination with select page size */}
          <CustomPagination
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            hasMore={hasMore}
          />
        </Paper>
      </Paper>

      <CustomConfirmationDialog
        open={openApproveAccessDialog}
        onClose={handleCancelApproveAccess}
        dialogTitle={"Approve Access"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to approve access?
          </DialogContentText>
        }
        onConfirm={handleConfirmApproveAccess}
      />

      <CustomConfirmationDialog
        open={openRejectAccessDialog}
        onClose={handleCancelRejectAccess}
        dialogTitle={"Reject Access"}
        disableConfirmBtn={reasonToRejectAccess?.length < 5}
        dialogContent={
          <Stack spacing={1.2}>
            <DialogContentText>
              Are you sure you want to reject access?
            </DialogContentText>
            <Box>
              <CustomTextFieldN
                size={"small"}
                // label={"Reason*"}
                placeholder={"Reason to reject access*"}
                type={"text"}
                fldType={"textR"}
                value={reasonToRejectAccess}
                setValue={setReasonToRejectAccess}
                // valueError={assemblyNoError}
                setValueError={() => null}
                variant={"standard"}
                txtFldStyle={styles.txtStyle}
              />
            </Box>
          </Stack>
        }
        onConfirm={handleConfirmRejectAccess}
      />
    </Box>
  );
}

export default PendingRequestsTab;

// Styles
const styles = {
  txtBold: {
    fontWeight: "bold",
  },
  filterBtnContainer: {
    mt: 1.4,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 1,
  },
  prTableContainer: {
    mt: 1.4,
    height: 470,
    width: "100%",
    mb: 8,
    border: "1px solid grey",
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
    border: "1px solid grey",
  },
  txtStyle: {
    width: "100%",
  },
};
