/**
 * @author Abdul Rahim M-abdul@au79consulting.com
 * @date 12-08-2024
 * @description  Type Approval API documentation Apis
 */

import actionType from "../actionTypes";
const typeApprovalAPI = {
  get_add_intersection: {
    api_name: "/ACM/intersections/details",
    https_method: "POST",
    action_type: actionType.GET_CREATE_INTERSECTIONS,
    description:
      "View the intersection details for the software module and legal requirements",
  },
  add_intersection: {
    api_name: "/ACM/intersections/create",
    https_method: "POST",
    action_type: actionType.CREATE_INTERSECTIONS,
    description:
      "Link the software modules with the legal requirements and create the tickets for the intersection,",
  },
  get_tickets: {
    api_name: "/ACM/intersections/tickets",
    https_method: "GET",
    action_type: actionType.GET_TICKETS,
    description: "Get Tickets",
  },
  update_project_status_tickets: {
    api_name: "/ACM/intersections/tickets/status",
    https_method: "PUT",
    action_type: actionType.UPDATE_PROJECT_STATUS_TICKETS,
    description: "Update Project Status",
  },
  re_create_tickets: {
    api_name: "/ACM/intersections/re-create",
    https_method: "POST",
    action_type: actionType.RE_CREATE_TICKETS,
    description: "Re-Create Tickets",
  },
  delete_tickets: {
    api_name: "/ACM/intersections/delete",
    https_method: "PUT",
    action_type: actionType.DELETE_DRAFT_TICKETS,
    description: "Delete Draft Tickets"
  }
};

export default typeApprovalAPI;
