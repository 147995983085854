import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import React from "react";
   
  function LegalSoftwareTable({ softwareLR }) {
    console.log("-->Software LR<---",softwareLR)
    return (
      <div>
        {" "}
        <Paper
          elevation={24}
          sx={{
            mt: "40px",
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
            border: "1px solid black", // Add border here
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              boxShadow: 2,
              mt: "20px",
              padding: "10px",
              // Add border here
            }}
          >
            <Paper
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Selected S/W Module Name
              </Typography>
              <strong>:</strong>
              <Typography>{softwareLR?.[0]?.name}</Typography>
            </Paper>
            <Paper
              sx={{
                display: "flex",
                padding: "10px",
                border: "1px solid black",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Version</Typography>
              <strong>:</strong>
              <Typography> {softwareLR?.[0]?.version}</Typography>
            </Paper>
          </Box>
   
          <TableContainer
            component={Paper}
            sx={{
              mt: "20px",
              maxHeight: "300px",
              overflowY: "auto",
              border: "1px solid black",
            }} // Add border here
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "1px solid black" }}>
                    <Typography sx={{ fontWeight: "bold" }}>S.No</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid black" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Legal Standard
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid black" }}>
                    <Typography sx={{ fontWeight: "bold" }}>Chapter</Typography>
                  </TableCell>
                  <TableCell sx={{ border: "1px solid black" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Legal Requirement
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {softwareLR?.[0]?.linked_lr_details?.length > 0 ? (
                  softwareLR?.[0]?.linked_lr_details?.map(
                    (linkedModule, linkedIdx) => (
                      <TableRow>
                        <TableCell sx={{ border: "1px solid black" }}>
                          <Typography>{linkedIdx + 1}</Typography>
                        </TableCell>
   
                        <TableCell sx={{ border: "1px solid black" }}>
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Name
                            </Typography>
                            <strong>:</strong>
                            <Typography>
                              {linkedModule?.ls_details?.name}
                            </Typography>
                          </Box>
   
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Number
                            </Typography>
                            <strong>:</strong>
                            <Typography>
                              {linkedModule?.ls_details?.standard_number}
                            </Typography>
                          </Box>
   
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Revision
                            </Typography>
                            <strong>:</strong>
                            <Typography>
                              {linkedModule?.ls_details?.revision}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ border: "1px solid black" }}>
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Chapter Name
                            </Typography>
                            <strong>:</strong>
                            <Typography>{linkedModule?.chapter}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ border: "1px solid black" }}>
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Number
                            </Typography>
                            <strong>:</strong>
                            <Typography>{linkedModule?.req_number}</Typography>
                          </Box>
                          <Box sx={{ display: "flex" }}>
                            <Typography sx={{ fontWeight: "bold" }}>
                              Description
                            </Typography>
                            <strong>:</strong>
                            <Typography>{linkedModule?.description}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  )
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography textAlign="center" sx={{ color: "red" }}>
                      Data Not Found
                    </Typography>
                  </Box>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
   
  export default LegalSoftwareTable;
   
