/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 30-06-2024
 * @description Component for handling the AdminSettings.
 */

import React, { useState } from "react";

import Box from "@mui/material/Box";

import ZoneDetails from "./ZoneDetails";
import { useDispatch, useSelector } from "react-redux";
import PowerTrainClass from "./PowerTrainClass";
import VehicleFunction from "./VehicleFunction";
import VehicleClass from "./VehicleClass";
import ArchitectureTypes from "./ArchitectureTypes";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import profileActions from "../../redux/actions/profile";
import customConsole from "../../config/customConsole";
import ManufacturerDetails from "./ManufacturerDetails";
import DeliveryMarket from "./DeliveryMarket";

function AdminSettings() {
  //1.Redux
  const dispatch = useDispatch();
  const adminSettingsDetails = useSelector(
    (state) => state.adminSettingsReducer
  );

  //2.React useState
  const [open, setOpen] = React.useState(null);
  const [architectureDetails, setArchitectureDetails] = React.useState([]);
  const [zoneDetails, setZoneDetails] = React.useState([]);

  //3.React useEffect

  //Dispatch Action for Get Admin Settings
  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_admin_settings,
        show_toast: false,
      })
    );
  }, []);

  //setState for Architecture Details and Zone Details
  React.useEffect(() => {
    customConsole("Admin Settings Details");
    customConsole(adminSettingsDetails);
    if (
      adminSettingsDetails &&
      adminSettingsDetails?.adminSettings &&
      adminSettingsDetails?.adminSettings?.data &&
      adminSettingsDetails?.adminSettings?.data?.architecture_types
    ) {
      setArchitectureDetails(
        adminSettingsDetails.adminSettings.data.architecture_types
      );
    }

    if (
      adminSettingsDetails &&
      adminSettingsDetails?.adminSettings &&
      adminSettingsDetails?.adminSettings?.data &&
      adminSettingsDetails?.adminSettings?.data?.zone_details
    ) {
      setZoneDetails(adminSettingsDetails.adminSettings.data.zone_details);
    }
  }, [adminSettingsDetails]);

  //4.Functions
  const handleClick = (value) => {
    setOpen(open === value ? null : value);
  };

  return (
    <Box sx={styled.box}>
      <ArchitectureTypes
        open={open}
        handleClick={handleClick}
        architectureDetails={architectureDetails}
      />

      <ZoneDetails
        open={open}
        handleClick={handleClick}
        zoneDetails={zoneDetails}
      />
      <PowerTrainClass open={open} handleClick={handleClick} />
      <VehicleFunction open={open} handleClick={handleClick} />
      <VehicleClass open={open} handleClick={handleClick} />
      <ManufacturerDetails open={open} handleClick={handleClick} />
      <DeliveryMarket open={open} handleClick={handleClick} />
    </Box>
  );
}

export default AdminSettings;

const styled = {
  box: {
    padding: "50px",
    marginBottom: "20px",
  },
};
