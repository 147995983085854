/**
 * @author Bharathan - bharathan@au79consulting.com
 * @date 09-08-2024
 * @description This is Link Page for Virtual Type Approval Page
 */

//Importing the required components/functions from the libraries
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import SoftwareToLegalLinks from "./SoftwareToLegalLinks";
import LegalToSoftwareLinks from "./LegalToSoftwareLinks";
import appTheme from "../../../assets/AppTheme/appTheme";

//Link Component to view the concern S/W Modules and Legal Requirements to be Link
function Links({
  projectOptionsList,
  selectedProject,
  setSelectedProject,
  selectedProjectDetails,
  selectedProjectId,
  selectedProjectIsZonal,
  selectedProjectZonesList,
  setTabValue,
  setLinkTab,
  tabValue,
  setShowIntersDelTicket

}) {
  //useState to handle multiple states
  const [showLegalToSoftware, setShowLegalToSoftware] = useState(true);
  return (
    //Container for the Link Page
    <Box>
      <Box sx={styles.box}>
        {/* <Button sx={{ ...appTheme.commonBtnStyle, ...styles.button }}>
          Import
        </Button>

        <Button sx={{ ...appTheme.commonBtnStyle, ...styles.button }}>
          Export
        </Button> */}

        {/* <Button
          sx={{ ...appTheme.commonBtnStyle, ...styles.button }}
          onClick={() => setShowLegalToSoftware(!showLegalToSoftware)}
        >
          {showLegalToSoftware ? "Legal To S/W" : "S/W To Legal"}
        </Button> */}
        {/* 
        <Button sx={{ ...appTheme.commonBtnStyle, ...styles.button }}>
          Link
        </Button> */}
      </Box>

      {showLegalToSoftware ? (
        <SoftwareToLegalLinks
          projectOptionsList={projectOptionsList}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          selectedProjectDetails={selectedProjectDetails}
          selectedProjectId={selectedProjectId}
          selectedProjectIsZonal={selectedProjectIsZonal}
          selectedProjectZonesList={selectedProjectZonesList}
          setTabValue={setTabValue}
          setLinkTab={setLinkTab}
          tabValue={tabValue}
          setShowIntersDelTicket={setShowIntersDelTicket}

        />
      ) : (
        <LegalToSoftwareLinks
          projectOptionsList={projectOptionsList}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          selectedProjectDetails={selectedProjectDetails}
          selectedProjectId={selectedProjectId}
          selectedProjectIsZonal={selectedProjectIsZonal}
          selectedProjectZonesList={selectedProjectZonesList}
        />
      )}
    </Box>
  );
}

export default Links;

//Styles for the page
const styles = {
  box: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "15px",
  },
  button: {
    variant: "contained",
  },
};
