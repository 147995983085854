/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-08-2024
 * @description : Legal requirements API details to perform a action
 */

import actionType from "../actionTypes";

const legalRequirements = {
    // Legal standard
    get_legal_standard_details: {
        api_name: "/ACM/legal/standard/view",
        https_method: "GET",
        action_type: actionType.GET_LEGAL_STANDARD_DETAILS,
        description: "",
    },
    get_legal_standard_main_details: {
        api_name: "/ACM/legal/standard/view",
        https_method: "GET",
        action_type: actionType.GET_LEGAL_STANDARD_MAIN_DETAILS,
        description: "",
    },
    add_legal_standard: {
        api_name: "/ACM/legal/standard/add",
        https_method: "POST",
        action_type: actionType.ADD_LEGAL_STANDARD,
        description: "",
    },
    update_legal_standard: {
        api_name: "/ACM/legal/standard/update",
        https_method: "PUT",
        action_type: actionType.UPDATE_LEGAL_STANDARD,
        description: "",
    },
    // Legal requirements
    get_legal_requirements_details: {
        api_name: "/ACM/legal/requirement/view",
        https_method: "GET",
        action_type: actionType.GET_LEGAL_REQUIREMENTS_DETAILS,
        description: "",
    },
    get_legal_requirements_main_details: {
        api_name: "/ACM/legal/requirement/view",
        https_method: "GET",
        action_type: actionType.GET_LEGAL_REQUIREMENTS_MAIN_DETAILS,
        description: "",
    },
    add_legal_requirements: {
        api_name: "/ACM/legal/requirement/add",
        https_method: "POST",
        action_type: actionType.ADD_LEGAL_REQUIREMENTS,
        description: "",
    },
    update_legal_requirements: {
        api_name: "/ACM/legal/requirement/update",
        https_method: "PUT",
        action_type: actionType.UPDATE_LEGAL_REQUIREMENTS,
        description: "",
    },
};

export default legalRequirements;
