import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextFieldSP,
  MultiSelectTextField2,
  MultiSelectTextField3,
  SearchSelectTextField,
  SelectTextField,
} from "../../components";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import customConsole from "../../config/customConsole";
import {
  adminSettingsAPI,
  HandleApiActions,
  profileActions,
  projectConfigAPI,
} from "../../redux/actions";
import { GetSingleImages } from "../../redux/actions/actionHandler";
import moment from "moment";
import { Avatar, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoButton from "../../components/infoButton/InfoButton";

function ReFirstScreen({
  close,
  selectedSortColumn,
  selectedSortOrder,
  recreateProject,
  page,
  rowsPerPage,
}) {
  const projectStatus = {
    100: "ACTIVE_PRE_SOP",
    200: "ACTIVE_POST_SOP",
    300: "DE_ACTIVATED",
    500: "COMPLETED",
  };

  //1.Redux
  const dispatch = useDispatch();

  const projectDetailsError = useSelector(
    (state) => state.projectConfigReducer
  );
  console.log("projectDetailsError", projectDetailsError.msg);

  const userInSystem = useSelector(
    (state) => state.userDetailsReducer?.userDetailsList
  );
  const timeZoneDetails = useSelector(
    (state) => state.countryAndPhoneCodeReducer.countryAndPhoneCode
  );
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );
  const architectureTypesDetails = useSelector(
    (state) => state.adminSettingsReducer
  );
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData
  );
  const vehicleClassDetails = useSelector((state) => state.vehicleClassReducer);

  // const projectStatusList = Object.keys(projectStatusDetails?.project_status);
  // const projectStatusList = Object.entries(
  //   projectStatusDetails?.project_status
  // ).map(([key, value]) => ({
  //   label: key,
  //   value: value,
  // }));

  customConsole(
    "---vehiclerStatusDetails---",
    projectStatusDetails?.vehicle_status
  );

  ///2.React useState
  const [vfData, setVfData] = useState([]);
  const [mftData, setMftData] = useState([]);
  const [pcData, setPcData] = useState([]);
  const [architectureTypes, setArchitectureTypes] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [vcData, setVcData] = useState([]);
  const [image, setImage] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [DmData, setDMData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [region, setRegion] = useState("");
  useEffect(() => {
    console.log(region);
    debugger;
  }, [region]);
  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectPS, setSelectPS] = useState("");

  const [name, setName] = useState("");
  const [mftDetailsState, setMftDetailsState] = useState(null);
  const [DMState, setDMState] = useState(null);
  const [commercialName, setCommercialName] = useState("");
  const [vehiclePlatform, setVehiclePlatform] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");

  const [selectedItemsVf, setSelectedItemsVf] = useState([]);
  const [selectedItemsPc, setSelectedItemsPc] = useState([]);
  const [selectedItemsAt, setSelectedItemsAt] = useState("");
  const [selectedItemsZone, setSelectedItemsZone] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedCoOwners, setSelectedCoOwners] = useState(null);

  //error useState
  const [nameError, setNameError] = useState(false);
  const [commercialNameError, setCommercialNameError] = useState(false);
  const [manufacturerNameError, setManufacturerNameError] = useState(false);
  const [manufacturerAddressError, setManufacturerAddressError] = useState("");
  const [vehiclePlatformError, setVehiclePlatformError] = useState("");
  const [vehicleStatusError, setVehicleStatusError] = useState("");

  useEffect(() => {
    console.log("----Changing recreate project---");
    console.log(recreateProject);

    if (recreateProject) {
      setRegion(recreateProject?.region || "");
      debugger;
      setCountry(recreateProject.country || "");
      setSelectPS(recreateProject.status || "");
      setName(recreateProject.name || "");
      setCommercialName(
        recreateProject.name === name ? name : recreateProject.trade_mark || ""
      );
      setMftDetailsState(recreateProject?.mft_details);
      setVehiclePlatform(recreateProject.vehicle_platform || "");
      setVehicleStatus(recreateProject.vehicle_status || "");
      setSelectedItemsVf(recreateProject.veh_funcs?.map((data) => data) || []);
      setSelectedItemsPc(
        recreateProject.pwrtrn_class?.map((data) => data) || []
      );
      setSelectedItemsAt(recreateProject.architecture_name || "");
      setSelectedItemsZone(
        recreateProject?.project_zones?.map((data) => ({
          zone_name: data?.zone_name || "",
          position: data?.position || "",
          sequence: data?.sequence || 0,
        })) || []
      );
      setSelectedClass(recreateProject.veh_class || "");
      setDMState(recreateProject?.delivery_market || "");
      setSelectedCoOwners(
        recreateProject?.co_owners?.map((data) => data) || []
      );
    }
  }, [recreateProject]);

  useEffect(() => {
    console.log("----powertrain--");
    console.log(selectedItemsPc);
  }, [selectedItemsPc]);

  //3.React useEffect
  useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_admin_settings }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
    dispatch(HandleApiActions({ ...projectConfigAPI.get_users_list }));
  }, []);

  useEffect(() => {
    customConsole("TimezoneDetails", timeZoneDetails);
    if (timeZoneDetails && timeZoneDetails.length > 0) {
      const sortedRegions = timeZoneDetails?.map((data) => data?.time_zone);

      setRegions(sortedRegions);
      const countryOptions = timeZoneDetails?.map((data) => data?.country);

      setCountries(countryOptions);
    }
  }, [timeZoneDetails]);

  useEffect(() => {
    const projectStatusData = Object.entries(
      projectStatusDetails?.project_status
    ).map(([key, value]) => ({
      label: formatString(key),
      value: value,
    }));
    console.log("----projectStatusData----", projectStatusData);
    setProjectData(projectStatusData);

    const vehicleStatusData = projectStatusDetails?.vehicle_status?.map(
      (el, idx) => ({
        value: el,
        label: formatString(el),
      })
    );

    setVehicleData(vehicleStatusData);
  }, [projectStatusDetails]);

  useEffect(() => {
    customConsole("vehicleFunctionDetails......");

    customConsole(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
    setVfData(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
  }, [vehicleFunctionDetails]);

  useEffect(() => {
    customConsole("vehicleClassDetails......");

    customConsole(vehicleClassDetails?.vehicleClassDetails?.data);
    setVcData(vehicleClassDetails?.vehicleClassDetails?.data);
  }, [vehicleClassDetails]);

  useEffect(() => {
    customConsole("powerTrainDetails");
    customConsole(powerTrainClassDetails?.powerTrainClassDetails?.data);
    setPcData(powerTrainClassDetails?.powerTrainClassDetails?.data);
  }, [powerTrainClassDetails]);

  useEffect(() => {
    customConsole("architectureTypes");
    customConsole(
      architectureTypesDetails?.adminSettings?.data?.architecture_types || []
    );
    setArchitectureTypes(
      architectureTypesDetails?.adminSettings?.data?.architecture_types || []
    );
    setZoneData(
      architectureTypesDetails?.adminSettings?.data?.zone_details || []
    );
    setMftData(
      architectureTypesDetails?.adminSettings?.data?.vehicle_manufacturers || []
    );
    setDMData(
      architectureTypesDetails?.adminSettings?.data?.product_delivery_markets ||
        []
    );
  }, [architectureTypesDetails]);

  useEffect(() => {
    if (vcData && vcData.length) {
      customConsole("---vcData----");
      customConsole(vcData);
      const imageUrl = async () => {
        const fetchUrlArr = await Promise.all(
          vcData.map(async (item) => {
            customConsole("---Image URL----");
            customConsole(item.image);
            try {
              const response = await GetSingleImages({ image: item.image });
              return { ...item, imageUrl: response };
            } catch (error) {
              customConsole(error);
              return { ...item, imageUrl: "" };
            }
          })
        );
        customConsole("----Fetch Url--");
        customConsole(fetchUrlArr);
        setImage(fetchUrlArr);
      };
      imageUrl();
    }
  }, [vcData]);

  useEffect(() => {
    console.log("----Zone change--");
    console.log(selectedItemsZone);
  }, [selectedItemsZone]);

  // const timeZones = Array.from(
  //   new Set(timeZoneDetails?.map((item) => item?.time_zone))
  // );

  // const ctry = timeZoneDetails.filter((item) => item?.time_zone === region);

  // customConsole("time zones", timeZones);
  // customConsole("ctry", ctry);

  // const timeZoneOptions = timeZones
  //   ?.map((tz) => ({ value: tz, label: tz }))
  //   .sort((a, b) => {
  //     return a.value.localeCompare(b.value);
  //   });
  // customConsole("timeZoneOptions", timeZoneOptions);

  // const countryOptions = ctry?.map((country) => ({
  //   value: country.country,
  //   label: country.country,
  // }));

  useEffect(() => {
    if (Array.isArray(userInSystem)) {
      const filteredUserDetails = userInSystem.map((data) => ({
        fullname: data?.fullname || "N/A", // Default value if fullname is missing
        email: data?.email || "N/A", // Default value if email is missing
        user_id: data?.user_id || "N/A", // Default value if user_id is missing
      }));

      console.log("filteredUserDetails", filteredUserDetails);

      setUserData(filteredUserDetails);
    } else {
      console.warn("userInSystem is not an array:", userInSystem);
      setUserData([]); // or set a default value if needed
    }
  }, [userInSystem]);

  const handleCreate = () => {
    const reqObj = {
      name: name,

      ...(commercialName?.length > 0 ? { trade_mark: commercialName } : null),

      vehicle_platform: vehiclePlatform,
      status: selectPS,
      ...(vehicleStatus && {
        vehicle_status: vehicleStatus,
      }),
      architecture_name: selectedItemsAt,
      mft_details: mftDetailsState,
      ...(selectedItemsZone?.length > 0 &&
      selectedItemsAt?.toLowerCase() === "zonal"
        ? { zones: selectedItemsZone }
        : {}),

      vehicle_class: parseInt(selectedClass),

      pwrtrn_class:
        selectedItemsPc && selectedItemsPc.length
          ? selectedItemsPc?.map((str) => parseInt(str))
          : undefined,
      vehicle_funcs: selectedItemsVf?.map((str) => parseInt(str)),
      current_time: moment().format(),
      country: country,
      region: region,
      ...(DMState?.length > 0
        ? {
            delivery_market: DMState,
          }
        : null),
      ...(selectedCoOwners?.length > 0
        ? {
            co_owners: selectedCoOwners,
          }
        : null),
    };

    console.log("reqObj...", reqObj);
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.add_project,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_project,
          params: {
            page_number: page,
            page_size: rowsPerPage,
            order_by_column: selectedSortColumn,
            order_by_sort: selectedSortOrder,
          },
        })
      );
    });

    if (
      name?.length > recreateProject.name?.length ||
      name.length < recreateProject.name?.length
    ) {
      close(false);
    }
  };

  customConsole("selectedItemsPc", selectedItemsPc);
  customConsole("selectedItemsZone", selectedItemsZone);

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    customConsole("event", event.target.value);
  };

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }

  useEffect(() => {
    customConsole("---Image---");
    customConsole(image);
  }, [image]);

  useEffect(() => {
    const timeZoneFilter = timeZoneDetails.filter(
      (item) => item?.country?.toUpperCase() === country?.toUpperCase()
    );
    const timeZoneOptions = timeZoneFilter?.map((data) => data?.time_zone);
    console.log("timeZoneOptions...", timeZoneOptions);
    setRegion(timeZoneOptions?.[0]);
  }, [country, timeZoneDetails, recreateProject]);

  console.log("projecData", projectData);
  console.log("region", region);

  return (
    <div>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ fontStyle: "italic", mt: "10px" }}>
            Required fields are marked with asterisk *
          </Typography>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
            }}
          >
            <SelectTextField
              required
              size={"small"}
              label={"Project Status"}
              optionsList={projectData}
              selectedValue={selectPS}
              setSelectedValue={setSelectPS}
              variant={"filled"}
              sltFldStyle={{
                width: "150px",
              }}
            />
            <InfoButton infoKey={"project_status"} color={"grey"} />
          </Box>
        </Box>
        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box sx={{ display: "flex", mr: "35px" }}>
            <Box
              sx={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              <SearchSelectTextField
                key={"country"}
                optionsList={countries}
                selectedValue={country}
                setSelectedValue={setCountry}
                size={"small"}
                label={"Country*"}
                txtFldStyle={{ width: "200px" }}
              />
            </Box>
            <Box sx={{ marginTop: "10px", marginLeft: "10px" }}>
              <SearchSelectTextField
                size={"small"}
                label={"Time Zone*"}
                placeholder={"Time Zone*"}
                noOptionsText={"No Time Zone"}
                optionsList={regions}
                selectedValue={region}
                setSelectedValue={setRegion}
                txtFldStyle={{
                  width: "180px",
                }}
              />
            </Box>
          </Box>
        </Box>
        {/* 1. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>1</Avatar>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>Project Name *</Typography>
            <CustomTextFieldSP
              size="normal"
              placeholder="Name of the Project"
              required
              value={name}
              setValue={setName}
              txtFldStyle={{ width: "100%" }}
              validationRules={{ minLength: 3 }}
              setError={setNameError}
              error={nameError !== ""}
            />
          </Box>
          <InfoButton infoKey={"name_of_the_project"} color={"grey"} />
        </Box>

        {/* 2. */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "25px",
          }}
        >
          <Avatar>2</Avatar>
          <Box sx={{ ml: "20px" }}>
            <Typography sx={{ fontWeight: "bold" }}>Trademark</Typography>
            <CustomTextFieldSP
              size="small"
              placeholder="Trademark"
              value={commercialName}
              setValue={setCommercialName}
              txtFldStyle={{
                width: "200px",
                mr: "10px",
              }}
              validationRules={{ minLength: 3 }}
              setError={setCommercialNameError}
              error={commercialNameError !== ""}
            />
          </Box>
          <InfoButton infoKey={"trademark"} color={"grey"} />
          <Box sx={{ ml: "20px" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              Vehicle Platform *
            </Typography>
            <CustomTextFieldSP
              required
              size="small"
              placeholder="Vehicle Platform"
              value={vehiclePlatform}
              setValue={setVehiclePlatform}
              txtFldStyle={{
                width: "200px",

                mr: "10px",
              }}
              validationRules={{ minLength: 3 }}
              setError={setVehiclePlatformError}
              error={vehiclePlatformError !== ""}
            />
          </Box>
          <InfoButton infoKey={"vehicle_platform"} color={"grey"} />

          <Box sx={{ ml: "20px" }}>
            <Typography sx={{ fontWeight: "bold" }}>Delivery Market</Typography>
            <Autocomplete
              value={DMState}
              options={DmData}
              onChange={(event, newValue) => {
                if (newValue) {
                  setDMState(newValue);
                }
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "200px", mr: "10px" }}
                  {...params}
                  variant="standard"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Typography>{option}</Typography>
                  </Box>
                </li>
              )}
            />
          </Box>

          <Box sx={{ ml: "15px", mb: "15px" }}>
            <Typography sx={{ fontWeight: "bold" }}>Vehicle Status</Typography>
            <SelectTextField
              size={"small"}
              optionsList={vehicleData}
              selectedValue={vehicleStatus}
              setSelectedValue={setVehicleStatus}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
              }}
            />
          </Box>
          <InfoButton infoKey={"vehicle_status"} color={"grey"} />
        </Box>

        {/* 3. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>3</Avatar>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              Type Of Architecture *
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={selectedItemsAt}
                onChange={(event, newValue) => setSelectedItemsAt(newValue)}
                options={architectureTypes?.map((data) => data.name) || []}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "100%" }}
                    {...params}
                    variant="standard"
                    placeholder="Select an Architecture"
                  />
                )}
                isOptionEqualToValue={(option, value) => option === value}
              />
            </FormControl>

            <Box>
              {selectedItemsAt &&
              architectureTypes?.find((item) => item?.name === selectedItemsAt)
                ?.is_zones_available ? (
                <Box mt="20px">
                  <Typography fontWeight={"bold"}>
                    Select the Zones & Positions*
                  </Typography>

                  <Box>
                    <MultiSelectTextField3
                      disabled={!selectedItemsZone}
                      size="medium"
                      label="Select Zones"
                      optionsList={zoneData}
                      selectedValues={selectedItemsZone}
                      setSelectedValues={setSelectedItemsZone}
                      sltFldStyle={{ width: "100%" }}
                    />
                  </Box>
                </Box>
              ) : (
                <Box mb="10px"></Box>
              )}
            </Box>
          </Box>
          <InfoButton infoKey={"type_of_architecture"} color={"grey"} />
        </Box>

        {/* 4. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>4</Avatar>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              Vehicle Class *
            </Typography>
            <FormControl fullWidth sx={{ width: "100%" }}>
              <Select
                variant="standard"
                value={selectedClass}
                onChange={handleClassChange}
                sx={{ mb: 2 }}
                label="Select Vehicle Class"
              >
                {image &&
                  image?.map((data, idx) => (
                    <MenuItem key={data?.id} value={data?.id}>
                      <Box display="flex" key={data?.id} value={data?.id}>
                        <Box>
                          <img
                            src={data.imageUrl}
                            alt=""
                            style={{
                              width: "56px",
                              height: "56px",
                              marginRight: "8px",
                            }}
                          />
                        </Box>

                        <Box display="flex" flexDirection="column">
                          <Typography fontWeight="bold">
                            {" "}
                            {data?.name}
                          </Typography>
                          <Typography>
                            {data?.class}-{data?.sub_class}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <InfoButton infoKey={"vehicle_class"} color={"grey"} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Box sx={{ ml: "30px" }}></Box>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold", ml: "10px" }}>
              {" "}
              Vehicle Function *
            </Typography>
            <MultiSelectTextField2
              mutiple
              size={"small"}
              label={"Select Vehicle Function"}
              optionsList={vfData}
              selectedValues={selectedItemsVf}
              setSelectedValues={setSelectedItemsVf}
              sltFldStyle={{
                textAlign: "start",
                width: "100%",
                ml: "10px",
                mt: "5px",

                backgroundColor: "white",
              }}
            />
          </Box>
          <InfoButton infoKey={"vehicle_function"} color={"grey"} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Box sx={{ ml: "30px" }}></Box>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold", ml: "10px" }}>
              {" "}
              PowerTrain Class
            </Typography>
            <MultiSelectTextField2
              mutiple
              variant="standard"
              size={"small"}
              label={"Select Powertrain Class [Optional]"}
              optionsList={pcData}
              selectedValues={selectedItemsPc}
              setSelectedValues={setSelectedItemsPc}
              sltFldStyle={{
                width: "100%",
                textAlign: "start",
                ml: "10px",

                backgroundColor: "white",
              }}
            />
          </Box>
          <InfoButton infoKey={"power_train_class"} color={"grey"} />
        </Box>

        {/* 5. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>5</Avatar>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              Manufacturer Details *
            </Typography>
            <Autocomplete
              value={mftDetailsState}
              options={mftData}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue) {
                  setMftDetailsState({
                    name: newValue.name,
                    address: newValue.address,
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Manufacturer Details"
                  variant="standard"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                      {option?.name}
                    </Typography>
                    <Box>
                      <Typography>{option?.address}</Typography>
                    </Box>
                  </Box>
                </li>
              )}
            />
          </Box>
          <InfoButton infoKey={"manufacturer_name"} color={"grey"} />
        </Box>

        {/* 6. */}
        <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
          <Avatar>6</Avatar>
          <Box sx={{ ml: "20px", flex: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {" "}
              Select Co-Owners
            </Typography>
            <Autocomplete
              multiple
              options={userData || []}
              disableCloseOnSelect
              value={userData?.filter((user) =>
                selectedCoOwners?.includes(user?.user_id)
              )}
              onChange={(event, newValue) => {
                setSelectedCoOwners(newValue.map((user) => user?.user_id));
              }}
              getOptionLabel={(option) => option?.fullname}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps}>
                    <Box>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        checked={selected}
                      />
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {option?.fullname}
                      </Typography>
                      <Typography>{option?.email}</Typography>
                    </Box>
                  </li>
                );
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Select the Co-Owners"
                  placeholder="Co Owners"
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          marginTop: "25px",
          marginRight: "10px",
          border: 1,
        }}
        flexItem
      />
      <Box mt="10px" display="flex" justifyContent="flex-end">
        <CustomButton
          handleOnClick={handleCreate}
          disabled={
            name?.length > 2 &&
            region &&
            country &&
            vehiclePlatform?.length > 2 &&
            selectedItemsVf?.length > 0 &&
            selectedItemsAt?.length > 0 &&
            mftDetailsState
              ? false
              : true
          }
          size="medium"
          btnName={" Recreate Project"}
          variant="contained"
          startIcon={<AddIcon />}
        />
      </Box>
    </div>
  );
}

export default ReFirstScreen;
